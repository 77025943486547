import { Button, TextField } from "@material-ui/core";
import { useState } from "react";

import { useApi } from '../../hooks/useApi';

import { LoginForm } from "../ui/LoginForm";

export function AccountsForgot() {
    const { sendPost } = useApi();

    const [ email, setEmail ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ message, setMessage ] = useState( '' );

    const onSubmit = async () => {
        try {
            // Clear when submitting to give feedback something changed.
            setError( '' );

            const response = await sendPost( 'account/forgot', { email } );

            setMessage( response.message );
        } catch ( error: any ) {
            setError( error.message );
        }
    };

    return <LoginForm error={error} onSubmit={onSubmit}>
        <h1>Forgot Your Password</h1>

        { message ? <p>{message}</p> : '' }

        {/* Use obscured class so that the height doesn't collapse with much less content. */}
        <div className={ message ? 'obscured' : '' }>
            <p>Enter your email address.</p>
            <div>
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={ event => setEmail( event.target.value ) }
                />
            </div>
            <div>
                <Button variant="contained" type="submit">Send Email</Button>
            </div>
        </div>
</LoginForm>;
}
