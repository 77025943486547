import { Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

interface Props {
    path: string;
    private?: boolean;
    children: any;
}

export function AuthRoute( props: Props ) {
    const { isExpired, isLoggedIn } = useAuth();

    // If logged in but it's expired, force a logout.
    if ( isLoggedIn && isExpired && props.path !== '/logout' ) {
        return <Navigate to="/logout" />;
    }

    // Default to requiring authentication.
    const isPrivate = props.private ?? true;

    if ( isPrivate && !isLoggedIn ) {
        return <Navigate to="/login" />;
    }

    if ( !isPrivate && isLoggedIn ) {
        return <Navigate to="/" />;
    }

    return props.children;
}
