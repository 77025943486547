import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// Removing soon
import { ReusableProvider } from 'reusable';

import { App } from './App';
import { store } from './state/State';

import './css/app.scss';

// React 18

// import { createRoot } from 'react-dom/client';

// const container = document.getElementById( 'app' ) as HTMLElement;
// const root = createRoot( container );

// @ts-ignore
// eslint-disable-next-line
// root.render(
//     <BrowserRouter>
//         {/* Add state management provider to root so chidren can use createStore(). Requires router so must be done inside BrowserRouter. */}
//         <ReusableProvider>
//             <App />
//         </ReusableProvider>
//     </BrowserRouter>
// );

// React 17

import { render } from 'react-dom';

render(
    // <React.StrictMode>
        <BrowserRouter>
            {/* Add state management provider to root so chidren can use createStore(). Requires router so must be done inside BrowserRouter. */}
            <ReusableProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </ReusableProvider>
        </BrowserRouter>
    // </React.StrictMode>,
    ,
    document.getElementById( 'app' )
);
