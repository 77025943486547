import { FilterMultiValue, FiltersState, setAllFilters, setFilters, unsetFilters } from "./FiltersSlice";
import { useAppDispatch, useAppSelector as AppSelector, useAppSelector } from "./State";

let count = 1;

let initialLoaded = false;

export const useFiltersStore = ( prefix?: string, initial: FiltersState = {} ) => {

    const prefixSafe = prefix ?? '';

    const dispatch = useAppDispatch();

    const data: FilterMultiValue = useAppSelector( (state) => state.filters[ prefixSafe ] ) ?? {};
console.log( 'useFiltersStore return ', count++, prefix, data, AppSelector( (state) => state.filters ), useAppSelector( (state) => state.filters ) );
    
    const exports = {
        filters: data,
        getStoreFilter: ( key: string ) => {
console.log( 'store getFilter', key )
            return data[ key ];
        },
        getStoreFilters: () => data,
        setAllStoreFilters: ( data: FilterMultiValue ) => dispatch( setAllFilters( { prefix, data } ) ),
        setStoreFilter: ( key: string, value: any ) => dispatch( setFilters( { prefix, data: { [key]: value } } ) ),
        setStoreFilters: ( data: FilterMultiValue ) => dispatch( setFilters( { prefix, data: data } ) ),
        setStoreFiltersAtPrefix: ( prefix: string, data: FilterMultiValue ) => dispatch( setAllFilters( { prefix, data } ) ),
        unsetStoreFilter: ( key: string ) => dispatch( unsetFilters( [ key ] ) ),
        unsetStoreFilters: ( keys: string[] ) => dispatch( unsetFilters( keys ) )
    };

    if ( !initialLoaded ) {
        if ( Object.keys( initial ).length ) {
            exports.setAllStoreFilters( initial );
        }

        initialLoaded = true;
    }
console.log( 'exports', exports );
    return exports;
}

// setAllFilters: ( filters: FilterMultiValue ) => dispatch( setAllFilters( filters ) ),
// setFilter: ( key: string, value: any ) => dispatch( setFilters( { [key]: value } ) ),
// setFilters: ( filters: FilterMultiValue ) => dispatch( setFilters( filters ) ),
