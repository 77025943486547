import { useFields } from '../../../../hooks/useFields';
import { ColumnSelectTable } from '../../../../components/ui/ColumnSelectTable';

export function KissFacebookConversionCampaigns() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(ad|adgroup)?_/ ],
        getFields( [
            'fb_setup_campaign',
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3',
            'fb_kiss_traffic', 'fb_kiss_sales'
        ] )
    );

    const set = 'fb_ecom';

    return <ColumnSelectTable
        name='Facebook Conversion Campaigns'
        api='facebook/campaigns/ecom'
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
