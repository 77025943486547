import { Icon, Paper, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';

import { useFormat } from '../../hooks/useFormat';

interface Props {
    text?: string;

    value: any;

    type?: string;
    precision?: number;

    tooltip?: React.ReactNode;

    // For comparing.
    compareEnabled?: boolean;
    compareValue?: number;
    compareShowValue?: boolean;
    compareInverted?: boolean;
}

export function Card( props: Props ) {
    const { formatMoney, formatNumber, formatPercent } = useFormat();

    const format = ( value: any, type: string|any, precision: number|any ) => {
        const format = type ?? 'number';
        const digits = precision ?? props.precision;

        let text: string|null = null;
    
        // Use ... for unloaded content.
        if ( typeof( value ) === 'undefined' || value === null ) {
            text = '';
        } else if ( format === 'money' ) {
            // Make sure we have at least 2 digits since default is 0.
            text = formatMoney( value, digits && digits > 2 ? digits : 2 );
        } else if ( format === 'number' ) {
            text = formatNumber( value, digits ?? 0 );
        } else if ( format === 'percent' ) {
            text = formatPercent( value, digits ?? 2 );
        }

        return text;
    }

    let diffValue: number = 0;
    let diffText: any = "";
    let goodDiff: boolean|any = false;
    let badDiff: boolean|any = false;

    if ( props.compareEnabled ) {
        if ( props.compareValue ) {
            diffValue = ( props.value - props.compareValue ) / props.compareValue * 100;
        } else if ( props.compareValue === props.value ) {
            // Case when both are zero.
            diffValue = 0;
        } else {
            // Say its a 100% different if there is nothing to compare to.
            diffValue = 100;
        }

        // Use absolute value so that we can use an icon to show direction.
        diffText = format( Math.abs( diffValue ), 'percent', 1 );

        goodDiff = ( ( diffValue > 0 && !props.compareInverted ) || ( diffValue < 0 && props.compareInverted ) ) ;
        badDiff = ( ( diffValue > 0 && props.compareInverted)  || ( diffValue < 0 && !props.compareInverted ) ) ;
    }

    return <Paper className="card" elevation={3}>
        { props.tooltip
            ? <Tooltip
                className="tooltip"
                arrow
                title={props.tooltip}
            >
                <div className="text">{props.text}</div>
            </Tooltip>
            : <div className="text">{props.text}</div>
        }

        <div className="value">
            { format( props.value, props.type, props.precision ) }
            { props.compareEnabled
                ? <div className={ "diff" + ( goodDiff ? " good" : "" ) + ( badDiff ? " bad" : "" ) }>
                    { diffValue
                        ? <>
                            <Icon>{ diffValue > 0 ? 'arrow_drop_up' : 'arrow_drop_down' }</Icon>{diffText}
                        </>
                        : <span>&nbsp;</span>
                    }
                </div>
                : ''
            }
        </div>
        
        { props.compareShowValue ? <div className="value compare-value">{ format( props.compareValue, props.type, props.precision ) }</div> : '' }
    </Paper>;
}
