import { Box, Button, Checkbox, FormControlLabel, Grid, Icon, IconButton, InputAdornment, Paper, Snackbar, SnackbarContent, TextField, Toolbar } from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { useClipboard } from '../../hooks/useClipboard';
import { useFilters } from '../../hooks/useFilters';
import { useFormat } from '../../hooks/useFormat';

import { Column, ColumnSetIndex } from '../../hooks/useFields';
import { ColumnSelectPopover } from '../ui/ColumnSelectPopover';
import { FilteredDatedContent } from '../ui/FilteredDatedContent';

interface Props {
    title: string;
    api: string;

    // Object doesn't work here why?
    params?: { [key: string]: any };
    dimensionParams?: Object;

    defaultSort: string;

    columnSets: ColumnSetIndex;
    columnSet: string;
}

export function ChannelCreatives( props: Props ) {
    const { copy } = useClipboard();

    const { formatType } = useFormat();

    const [ numShown, setNumShown ] = useState( 6 );

    const [ searchText, setSearchText ] = useState( '' );

    const { canView } = useAuth();
    const { getFilter, setFilter } = useFilters();

    const [ alertOpen, setAlertOpen ] = useState( false );
    const [ singleMode, setSingleMode ] = useState( false );

    const [ columnSet, setColumnSet ] = useState( props.columnSet );
    const popoverAnchor: any = useRef();
    const [ popoverOpen, setPopoverOpen ] = useState( false );

    // Pulled out due to fix useEffect static checking a complex expression.
    const sortFilter = getFilter( 'sort' );

    useEffect( () => {
        for ( let field of fields ) {
            if ( field.name === getFilter( 'sort' ) ) {
                setFilter( 'sort_key', field.field );
                break;
            }
        }
    }, [ sortFilter ] );

    const closeAlert = () => {
        setAlertOpen( false );
    }

    const copyCreative = ( ad: any, fields: any ) => {
        // Join fields by newline for copying into deck.
        const text = ad.name + "\n"
            + fields.map(
                (column: any) => formatType( column.type ?? null, ad[ column.field ], column.precision ?? 0 )
            ).join("\n");

        copy( text );

        setAlertOpen( true );
    }

    const copyCreatives = ( ads: any[], fields: any, limit: number ) => {
        let text = ads.slice( 0, limit ).map( ad => ad.name ).join( "\t" ) + "\n";

        for ( let column of fields ) {
            text += ads.slice( 0, limit ).map( ad => formatType( column.type ?? null, ad[ column.field ], column.precision ?? 0 ) ).join( "\t" ) + "\n";
        }

        copy( text );

        setAlertOpen( true );
    }

    // Switching to a column set that doesn't have the current Sort Select value
    // creates a "Cannot read properties of undefined (reading 'value')". This
    // is from Material UI Select breaking when value is set to something not in
    // its list.
    const setColumnSetSafely = async ( name: string ) => {
        let fields = props.columnSets[ name ].fields.map( (item: Column) => item.name );

        // If the current sort filter value is not in the new column set, set it
        // to the first vlaue. This must be done async because we can't set
        // columnSet until we do.
        // 
        // Setting to undefined did not work even though it did before.
        if ( fields.indexOf( getFilter( 'sort' ) ) === -1 ) {
            await setFilter( 'sort', fields[ 0 ] );
        }

        setColumnSet( name );
    }

    const loadMore = () => {
        setNumShown( numShown + 6 );
    }

    let fields = props.columnSets[ columnSet ].fields;

    const extraFilters: any = [
        {
            name: 'Sort',
            code: 'sort',
            list: fields.map( (column: Column) => column.name ),
            default: props.defaultSort,
            all: false,
        }
    ];

    let params = {
        ...props.params,
        sort: props.defaultSort,
        fields: fields.map( ( item: Column ) => item.field ).join( ',' )
    };

    const render = ( data: any ) => {
        // Apply single filter first because it reduces item count the most.
        if ( singleMode ) {
            let singleData: any = {};

            for ( let item of data ) {
                if ( !singleData[ item.name ] ) {
                    singleData[ item.name ] = item;
                }
            }

            data = Object.values( singleData );
        }

        if ( searchText ) {
            let text = searchText.toLowerCase();

            data = data.filter( ( item: any ) => item.campaign.toLowerCase().includes( text ) || item.adgroup.toLowerCase().includes( text ) || item.name.toLowerCase().includes( text ) )
        }

        return <Paper className="content page-creatives">
            <Snackbar
                open={alertOpen}
                onClose={closeAlert}
                autoHideDuration={2000}
                anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
            >
                <SnackbarContent message="Copied to clipboard."></SnackbarContent>
            </Snackbar>

            { canView( getFilter( 'view' ), 'user' )
                ? <Grid container className="toolbar">
                    <Grid item xs={6}>
                        <Toolbar>
                            <TextField
                                value={searchText}
                                onChange={ (event: any) => setSearchText( event.target.value ) }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={ () => setSearchText('') } disabled={ searchText === '' }>
                                                <Icon>close</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                        </Toolbar>
                    </Grid>

                    <Grid item xs={6}>
                        <Toolbar>
                            <div>
                            <FormControlLabel
                                control={<Checkbox
                                    value={singleMode}
                                    onChange={ ( event: any, checked: boolean ) => setSingleMode( checked ) }
                                />}
                                label="Unique"
                            />
                            </div>

                            <IconButton onClick={ () => copyCreatives( data ?? [], fields, numShown ) }>
                                <Icon>file_copy</Icon>
                            </IconButton>

                            <Icon ref={popoverAnchor} onClick={() => setPopoverOpen( !popoverOpen )}>view_column</Icon>
                            <ColumnSelectPopover
                                anchor={popoverAnchor}

                                open={popoverOpen}
                                onClose={ () => {
                                    setPopoverOpen( !popoverOpen )
                                } }
                                onChange={ ( value: string ) => {
                                    setPopoverOpen( !popoverOpen );

                                    setColumnSetSafely( value );
                                } }

                                columns={ Object.values( props.columnSets ) }
                                value={columnSet}
                            />
                        </Toolbar>
                    </Grid>
                </Grid>
                : ''
            }

            <Grid container className="items">
                { data?.length ?
                    data.map(
                        ( ad: any, i: number ) => 
                            // Show content if the current position is less than
                            // the number we are showing.
                            i < numShown ? 
                                <Grid key={i} item xs={4} className="ad-container">
                                    <Box display="flex" justifyContent="center">
                                        {/* Set the height in frontend so we have some flexibility. */}
                                        <div className="ad">
                                            { ad.creative
                                                ? <div key={i} dangerouslySetInnerHTML={ { '__html': ad.creative.replace( /height="\d+"/, 'height="540"') } }></div>
                                                : ''
                                            }
                                            

                                            <div className="info">
                                                { canView( getFilter( 'view' ), 'user' )
                                                        ? <div className="copy">
                                                            <IconButton onClick={ () => copyCreative( ad, fields ) }>
                                                                <Icon>file_copy</Icon>
                                                            </IconButton>
                                                        </div>
                                                        : ''
                                                    }                                          
                                                <Grid container>
                                                    { canView( getFilter( 'view' ), 'user' ) 
                                                        ? <>
                                                            <Grid item xs={12} className="name center">{ad.campaign}</Grid>
                                                            <Grid item xs={12} className="name center">{ad.adgroup}</Grid>
                                                            <Grid item xs={12} className="name center">{ad.name}</Grid>
                                                        </>
                                                        : ''
                                                    }
                                                    { canView( getFilter( 'view' ), 'user' ) 
                                                        ? <>
                                                            <Grid item xs={3} className="stat">Links</Grid>
                                                            <Grid item xs={9} className="value links">
                                                                <a href={ad.ad_preview_link} target="_blank" rel="noreferrer">Preview</a>
                                                                , <a href={ad.post_link} target="_blank" rel="noreferrer">Post</a>
                                                                , <a href={ad.manager_link} target="_blank" rel="noreferrer">Manager</a>
                                                            </Grid>
                                                        </>
                                                        : ''
                                                    }

                                                    { fields.map(
                                                        ( column: Column, i: number ) => {
                                                            let value = formatType( column.type ?? null, ad[ column.field ], column.precision ?? 0 );

                                                            return <Fragment key={i}>
                                                                <Grid item xs={8} className="stat">{column.name}</Grid>
                                                                <Grid item xs={4} className="value">{value}</Grid>
                                                            </Fragment>
                                                        }
                                                    ) }

                                                    { ad.is_dc || ad.is_catalog
                                                        ? <Fragment>
                                                            <Grid item xs={8} className="stat">Type</Grid>
                                                            <Grid item xs={4} className="value">
                                                                <a href={ad.link} target="_blank" rel="noreferrer">
                                                                { ad.is_dc ? 'Dynamic' : '' }
                                                                { ad.is_catalog ? 'Catalog' : '' }
                                                                </a>
                                                            </Grid>
                                                        </Fragment>
                                                        : <Grid item xs={12}>&nbsp;</Grid>
                                                    }
                                                </Grid>
                                            </div>
                                        </div>
                                    </Box>
                                </Grid>
                                : ''
                    )
                    : <div className="message">No ads found.</div>
                }
            </Grid>

            { numShown < data?.length ?
                <Box display="flex" justifyContent="center">
                    <Button onClick={loadMore}>Load More</Button>
                </Box>
                : ''
            }
        </Paper>
    }

    return <FilteredDatedContent
        title={props.title}
        api={props.api}
        params={params}
        dimensionParams={props.dimensionParams}
        filters={extraFilters}
        render={render}
    />
}
