import { useDate } from "./useDate";
import { useFilters } from "./useFilters";

const getName = ( filters: any, getDate: any ) => {
    let name = 
        // Header
        ( document.querySelector( 'h1' )?.textContent ?? '' )
        // Subheader
        + ' ' + ( document.querySelector( 'h2' )?.textContent ?? '' )
        // Tab
        + ' ' + ( document.querySelector('.MuiTab-root.Mui-selected .MuiTab-wrapper')?.textContent ?? '' )
    ;

    // Filters
    // Add only certain filters to name.
    const allowed = [ 'area', 'funnel', 'store', 'objective', 'request' ];

    for ( let key in filters ) {
        // Ignore if value is 'all'.
        if ( allowed.indexOf( key ) !== -1 && filters[ key] !== 'all' ) {
            name += ' ' + filters[ key];
        }
    }

    // Dates
    name += ' ' + getDate( 'Y-m-d', filters.date_start )
        + ' to ' + getDate( 'Y-m-d', filters.date_end )
        // Must use compare from options because it detects if we loaded compare data. Filters will show if the compare toggle is on which is not the same.
        + ( filters.compare_enabled ? ' vs ' + getDate( 'Y-m-d', filters.compare_date_start ) + ' to ' + getDate( 'Y-m-d', filters.compare_date_end ) : '' )
    ;

    // Fix double spaces from empty values.
    name = name.replace( /  +/g, ' ' );

    return name;
}

export const usePage = () => {
    const { getFilters } = useFilters();
    const { getDate } = useDate();


    return {
        getDownloadName: () => getName( getFilters(), getDate )
    }
}
