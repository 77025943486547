export type Nullable<T> = T | null;

export interface ObjectType {
    [ key: string ]: any;
}

export const objectContains = ( larger?: ObjectType, smaller?: ObjectType ): boolean => {
    // Check if all of smaller is in larger.
    for ( let key of Object.keys( smaller ?? {} ) ) {
        if ( smaller?.[ key ] !== larger?.[ key ] ) {
            return false;
        }
    }

    return true;
}

export const objectEquals = ( a?: ObjectType, b?: ObjectType ): boolean => {
    for ( let key of Object.keys( a ?? {} ) ) {
        if ( b?.[ key ] !== a?.[ key ] ) {
            return false;
        }
    }

    for ( let key of Object.keys( b ?? {} ) ) {
        if ( a?.[ key ] !== b?.[ key ] ) {
            return false;
        }
    }

    return true;
}
