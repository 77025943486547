import { useFields } from '../../../hooks/useFields';
import { ColumnSelectTable } from '../../../components/ui/ColumnSelectTable';

export function TikTokCampaigns() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(ad|adset)?_/ ],
        getFields( [
            'tt_ecom', 'tt_budgets'
        ] )
    );

    const set = 'tt_ecom';

    return <ColumnSelectTable
        name='TikTok Campaigns'
        api='tiktok/campaigns'
        dimensionParams={{ platform: 'tiktok' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
        // enableSelect={true}
    />
}
