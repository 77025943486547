import fieldsData from '../data/fields.json';
import fieldSetsData from '../data/fields-sets.json';

export interface Column {
    name: string;
    field: string;
    type?: string;
    precision?: number;
}

export interface ColumnSet {
    name: string;
    code: string;
    fields: Column[];
}

export interface ColumnSetIndex {
    [ code: string ]: ColumnSet;
}

interface ColumnSetIndexItem {
    name: string;
    code: string;
    fields: string[];
}

export interface TabCardsTabData {
    name: string;
    cards: Column[][];
}

// Global variables added here so to avoid forcing consumers to pass them into
// all calls.
let fieldIndex: { [ name: string ]: Column } = {};
let setIndex: { [ name: string ]: ColumnSet } = {};

export function useFields() {
    // Create index list version indexed by field for ease of use.
    const fieldIndexHash: { [ category: string ]: Column[] } = fieldsData;

    // Reset data because if a re-render occurs during the loop, we are stuck in
    // an invalid statea and can't check for duplicates.
    fieldIndex = {};

    for ( let columnList of Object.values( fieldIndexHash ) ) {
        for ( let column of columnList ) {

            // Fields must be unique to avoid definition conflicts.
            if ( fieldIndex[ column.field ] ) {
                throw new Error( `Field ${column.field} already defined.` );
            }
    
            fieldIndex[ column.field ] = column;
        }
    }

    const setIndexHash: { [ category: string ]: ColumnSetIndexItem[] } = fieldSetsData;

    // Reset data for same reason as above.
    setIndex = {};

    // Export column sets indexed by code for simpler usability.
    for ( const setList of Object.values( setIndexHash ) ) {
        for ( const set of setList ) {
            // Don't allow duplicates for same reason as above.
            if ( setIndex[ set.code ] ) {
                throw new Error( `Field set ${set.code} already defined.` );
            }

            setIndex[ set.code ] = {
                name: set.name,
                code: set.code,
                fields: getFieldsFromIndex( set.fields )
            };
        }
    }

    return {
        getFields, getFieldsFromTabCards, getLayout, removeFields
    }
}

// Returns a field set.
const getFields = ( selected: string[], customIndex?: ColumnSetIndex ): ColumnSetIndex => {
    let allFields: ColumnSetIndex;

    // Use passed in fields (ex: from removeField)
    if ( customIndex ) {
        allFields = customIndex;
    } else {
        allFields = setIndex;
    }

    let selectedFieldsUnsorted: ColumnSetIndex = {};

    for ( let [ code, set ] of Object.entries( allFields ) ) {
        if ( selected.indexOf( code ) !== -1 ) {
            selectedFieldsUnsorted[ code ] = set;
        }
    }

    // Set them in the order they were asked for.
    let selectedFields: ColumnSetIndex = {};

    for ( let code of selected ) {
        if ( !selectedFieldsUnsorted[ code ] ) {
            throw new Error( `Field set ${code} not found.` );
        }

        selectedFields[ code ] = selectedFieldsUnsorted[ code ];
    }

    return selectedFields;
}

const getFieldsFromIndex = ( names: string[] ): Column[] => {
    let list: Column[] = [];

    for ( let name of names ) {
        if ( !fieldIndex[ name ] ) {
            throw new Error( `Field ${name} not found.` );
        }

        list.push( fieldIndex[ name ] );
    }

    return list;
}

const getFieldsFromTabCards = ( list: TabCardsTabData[] ): string[] => {
    let fields: { [name: string]: number } = {};

    for ( let tab of list ) {
        for ( let row of tab.cards ) {
            for ( let column of row ) {
                fields[ column.field ] = 1;
            }
        }
    }

    return Object.keys( fields );
}

const getLayout = ( fields: string[][] ): Column[][] => {
    let list: Column[][] = [];

    for ( let row of fields ) {
        list.push( getFieldsFromIndex( row ) );
    }

    return list;
}

// Field lists are verbose and will include campaign/adgroup/ad all the
// time. These need to be hidden in higher level views ex: campaign. This
// method helps with that repetitive logic.
const removeFields = ( names: (string|RegExp)[], customIndex?: ColumnSetIndex ): ColumnSetIndex => {
    let thisFields: ColumnSetIndex;

    // Use passed in fields (ex: from removeField)
    if ( customIndex ) {
        thisFields = customIndex;
    } else {
        thisFields = setIndex;
    }

    if ( !names.length ) {
        return thisFields;
    }

    let newValue: ColumnSetIndex = JSON.parse( JSON.stringify( thisFields ) );

    for ( let code in newValue ) {
        // Collect indexes to remove later so that we don't interrupt
        // the loop by removing an item as it's iterating.
        let remove: number[] = [];

        for ( let [ i, field ] of newValue[ code ].fields.entries() ) {

            let found = false;

            for ( let text of names ) {
                if ( field.field.match( text ) ) {
                    found = true;
                    break;
                }
            }

            if ( found ) {
                // Add to beginning instead of end so that higher
                // indexes are first. This way we can remove without
                // worrying about shifting indexes down.
                remove.unshift( i );
            }
        }

        for ( let index of remove ) {
            newValue[ code ].fields.splice( index, 1 );
        }
    }

    return newValue;
}
