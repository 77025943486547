import { Alert } from '@material-ui/lab';
import { Icon, Paper } from '@material-ui/core';
import { MutableRefObject, useState } from 'react';
import MaterialTable, { MTableActions, MTableBodyRow, MTableCell, MTableHeader, MTableToolbar } from '@material-table/core';

import { useApi } from '../../hooks/useApi';
import { useExport } from '../../hooks/useExport';
import { useFormat } from '../../hooks/useFormat';
import { usePage } from '../../hooks/usePage';

// Simplified encapsulation of Material Table action.
interface Action {
    icon: string;
    text: string;
    click: (event: any) => any;

    // Defaults to table.
    position?: 'row' | 'table';

    // Used to anchor UI for hovers.
    ref?: MutableRefObject<any>;
}

interface Column {
    name: string;
    field: string;
    type?: string;
    precision?: number;
    render?: any;
}

interface ColumnSet {
    name: string;
    code: string;
    fields: Column[];
}

interface ColumnSetHash {
    [ code: string ]: ColumnSet;
}

interface CompareSettings {
    enabled: boolean;
    keys?: string[];
    showValue?: boolean;
}

interface MethodHash {
    [key: string]: ( row: any ) => JSX.Element;
}

// Material Table Action
interface MTAction {
    icon: string | ( () => JSX.Element );
    tooltip: string;
    isFreeAction: boolean;
    onClick: ( event: any ) => any;
}

// Material Table Column
interface MTColumn {
    title: string;
    field: string;
    type?: string;
    render?: ( row: any ) => JSX.Element;
    customFilterAndSearch?: any;
    editable?: string;
}

interface Props {
    loading?: boolean;
    data: any[];

    className?: string;

    columns: Column[];

    freeze?: { [key: string]: number };

    render?: MethodHash;

    // Toolbar icons to add
    actions?: any[];

    rows?: number;
    rowOptions?: number[];

    enableDownload?: boolean;
    enableGroups?: boolean;
    enableHeader?: boolean;
    enableSearch?: boolean;
    enableSelect?: boolean;
    enableSort?: boolean;
    enableToolbar?: boolean;
    // Whether to show the column selection option
    enableColumnSelect?: boolean;

    actionsColumn?: number;

    edit?: any;

    emptyMessage?: string;

    compare?: CompareSettings;

    // detailPanel?: ( row: any ) => JSX.Element;
}

const exportData = ( columns: any, data: any, formatType: any, exportCsv: Function, getDownloadName: Function ) => {
    // Format data.

    // Create header from column names.
    const headerRow = columns.map( ( column: any ) => column.title.replace( 'Δ', 'Change' ) );

    const dataRows = data.map( ( item: any ) => {
        let row: any = [];

        for ( let i = 0; i < columns.length; i++ ) {
            let value = item[ columns[i].field ];

            let type = columns[i].type;

            if ( columns[i].field.match(/_diff$/) ) {
                // Use percents for diffs.
                value = formatType( 'percent', value, 2 );
            } else if ( columns[i].field.match(/_prev$/) ) {
                // Compare previous values don't exist on the item and so must be handled differently.
                value = formatType( item[ 'compare' ][ columns[i].field ], value, 2 );
            } else if ( type === 'percent' ) {
                value = formatType( type, value, 2 );
            } else if ( type === 'money' ) {
                value = formatType( type, value, 2 );
            }

            row.push( value );
        }

        return row;
    })

    const rows = [ headerRow, ...dataRows ];

    exportCsv( getDownloadName(), rows );
};

const getAction = ( simpleAction: Action ): MTAction => {
    // If icon has ref, add it to element.
    const icon = simpleAction.ref ? () => <Icon ref={simpleAction.ref}>{simpleAction.icon}</Icon> : simpleAction.icon ;

    const standardAction: MTAction = {
        icon: icon,
        tooltip: simpleAction.text,
        // Default to table.
        isFreeAction: ( simpleAction.position ?? 'table' ) === 'table',
        onClick: simpleAction.click,
        // iconProps: {
        //     classes: 'xxxxx'
        // },
    }

    return standardAction;
}

const getActions = ( simpleActions: Action[] ): MTAction[] => {
    let standardActions = [];

    for ( let action of simpleActions ) {
        standardActions.push( getAction( action ) );
    }

    return standardActions;
}

const getColumns = ( columns: Column[], data: any[], formatType: Function, compare?: CompareSettings, render?: MethodHash ) => {
    let tableColumns: MTColumn[] = [];

    for ( let item of columns ) {
        let column: MTColumn = { title: item.name, field: item.field };

        // if ( types && types[ field ] ) {
        if ( item.type ) {
            // Pass column type for improving exports.
            column.type = item.type;

            column.render = ( row: any ) =>
                // Show blank entry if row does not have field (ex: when
                // columns are switched)
                row[ item.field ] === undefined ? null
                    // Use its render if it has one.
                    : item.render ? item.render()
                        : formatType(
                            item.type ?? '',
                            row[ item.field ],
                            item.precision
                        );
        }

        // This should be passed in via Creatives.
        // if ( item.field === 'thumbnail' ) {
        //     column.render = ( row: any ) => {
        //         return <img src={row[ item.field]} alt='' />
        //     }
        // } else if ( item.field === 'preview_link' ) {
        //     column.render = ( row: any ) => {
        //         return <a href={row[ item.field ]} target='_blank'>Preview</a>
        //     }
        // }

        if ( render && render[ item.field ] ) {
            column.render = render[ item.field ];
        }

        if ( !columns.length ) {
            column.customFilterAndSearch = function( query: string, row: any ) {
                // Lowercase to avoid case issues.

                // Get each word of query.
                const queries = query.toLowerCase().split( /\s+/ );

                // Turn row into text.
                const text = JSON.stringify( row ).toLowerCase();

                // Check if each query matches.
                for ( let q of queries ) {
                    if ( !text.match( q ) ) {
                        return false;
                    }
                }

                return true;
            }
        }

        tableColumns.push( column );

        if ( compare?.keys?.length && compare?.keys.indexOf( item.field ) !== -1 ) {
            // Add diff.

            // Add to column list.
            let diffField = item.field + '_diff';

            let diffColumn = Object.assign(
                {},
                column,
                {
                    title: column.title + ' Δ',
                    field: diffField,
                    // Use percent type with precision 1 for diffs.
                    render: ( row: any ) => formatType(
                        'percent',
                        row[ diffField ],
                        1
                    )
                }
            );

            tableColumns.push( diffColumn );

            // Add to data.
            for ( let i in data ) {
                let oldValue = data[i].compare ? data[i].compare[ item.field ] : null;
                let newValue = data[i][ item.field ];

                let diff = 0;

                // We must choose defaults to avoid NaN calculation results.

                if ( newValue ) {
                    // If new value but no old value, default to 100% diff.
                    diff = oldValue ? ( newValue / oldValue - 1 ) * 100 : 100 ;
                } else {
                    // If no new value but there is old value, default to -100% diff. If no old value too, default to 0%.
                    diff = oldValue ? -100 : 0 ;
                }
                
                data[i][ diffField ] = diff;
            }

            // Add previous value.
            if ( compare.showValue ) {
                let previousField = item.field + '_previous';

                // Add to column list.
                let previousColumn = Object.assign(
                    {},
                    column,
                    {
                        title: column.title + ' Prev',
                        field: previousField,
                        // Use same formatting as field.
                        render: ( row: any ) => formatType(
                            item.type ?? null,
                            row[ previousField ],
                            item.precision
                        )
                    }
                );

                tableColumns.push( previousColumn );


                // Add to data.
                for ( let i in data ) {
                    data[i][ previousField ] = data[i].compare ? data[i].compare[ item.field ] : null;
                }
            }
        }
    }

    return tableColumns;
}

const hasAction = ( actions: Action[], name: string ) => {
    for ( let action of actions ) {
        if ( action.text === name ) {
            return true;
        }
    }

    return false;
}

function Table( props: Props ) {
    const { sendPost } = useApi();
    const { exportCsv } = useExport();
    const { formatType } = useFormat();
    const { getDownloadName } = usePage();

    const [ error, setError ] = useState('');
    const [ message, setMessage ] = useState('');

    const emptyMessage = props.emptyMessage ?? '...' ;

    // Auto-add enable if search is set.
    const enableToolbar = ( props.enableToolbar || props.enableSearch ) ? true : false ;

    // Convert column data into format for underlying table.
    let columns: MTColumn[] = [];

    if ( props.columns.length ) {
        columns = getColumns( props.columns, props.data, formatType, props.compare, props.render );
    }

    let classes = [ 'table' ];

    let componentProps: any = {};

    if ( props.className ) {
        classes = classes.concat( props.className.split( /\s+/ ) );
    }

    // Check if it false but default to true if it's not set.
    if ( !(props.enableHeader ?? true ) ) {
        classes.push( 'table-no-header' );
    }

    let editing: any = null;

    if ( props.edit ) {
        // Disable editing the wrong columns.
        for ( let i in columns ) {
            if (
                // If it isn't in the editable columns
                props.edit?.columnsEnabled?.indexOf( columns[ i ].field ) === -1
                // Or it is in the uneditable columns.
                || props.edit?.columnsDisabled?.indexOf( columns[ i ].field ) !== -1
            ) {
                columns[ i ].editable = 'never';
            }
        }

        editing = {
            onRowAdd: props.edit.onAdd,

            // Pass diff if updating.
            onRowUpdate: !props.edit.onUpdate ? null
                : ( newRow: any, oldRow: any ) => {
                    // See what was changed and only pass that.
                    let diff: any = {};

                    for ( let key of Object.keys( newRow ) ) {
                        if ( newRow[ key ] !== oldRow[ key ] ) {
                            diff[ key ] = newRow[ key ];
                        }
                    }

                    if ( !Object.keys(diff).length ) {
                        return new Promise( ( resolve: any, reject: any ) => {
                            setMessage( 'No changes made.' );
                            setError( '' );

                            resolve( 'No changes made.' );
                        } )
                    }

                    const { url, params, after } = props.edit.onUpdate( newRow, oldRow, diff );

                    return new Promise(
                        ( resolve: any, reject: any ) => {
                            // Clear display.
                            setError( '' );
                            setMessage( '' );

                            sendPost( url, params )
                            .then( ( response: any ) => {
                                setMessage( 'Changes saved.' );
                                setError( '' );

                                after( response );
                                resolve( response );
                            } )
                            .catch( err => {
                                setError( err.message );
                                setMessage( '' );
                                reject( err.message );
                            } );
                        }
                    )
                },
        };
    }

    let actions: Action[] = props.actions ?? [];

    // Check for duplicate because push gets duplicated when componenet is double rendered. This is a React issue.
    if ( props.enableDownload && !hasAction( actions, 'Download' ) ) {
        // Using actions.push() creates many duplicates every refresh so it must be reconstructed from props every time.
        actions = [
            ...( props.actions ?? [] ),
            {
                icon: 'get_app',
                text: 'Download',
                click: (event: any) => {
                    exportData( columns, props.data, formatType, exportCsv, getDownloadName )
                }
            }
        ];
    }

    // let filterFields: any = {};
    // for ( let i = 0; i < columns.length; i++ ) {
    //     filterFields[ columns[i].field ] = columns[i].title;
    // }

    // const [ hoverId, setHoverId ] = useState([null,null]);

    return <Paper className={ classes.join( ' ' ) }>
        { !error ? '' : <Alert severity="error">{error}</Alert> }
        { !message ? '' : <Alert severity="success">{message}</Alert> }

        <MaterialTable
            // className="tablexxxxxx"

            // [ { title: 'Display Name', field: 'key', render: ( row: any ) => row.key } ]
            columns={columns}
            // [ { field1: 'A', field2: 'B', link: 'www' } ]
            data={props.data}

            editable={editing}
            // onSearchChange={ function() { console.log('search', arguments) } }
            // onSearchChangeDebounce={ function() { console.log('search debounce', arguments) } }
            // onOrderChange={ function() { console.log('order', arguments) } }
            // onQueryChange={ function() { console.log('query', arguments) } }
            isLoading={ props.loading ?? false }
            options={{
                pageSize: props.rows ?? 10 ,
                pageSizeOptions: props.rowOptions ?? [ 10, 20, 50, 100 ] ,

                search: props.enableSearch ?? true ,
                selection: props.enableSelect ?? false ,
                sorting: props.enableSort ?? true ,
                toolbar: enableToolbar,

                showTitle: false,

                // Put actions at end of row
                actionsColumnIndex: props.actionsColumn ?? null,

                grouping: props.enableGroups ?? false,

                fixedColumns: props.freeze ?? {} ,
                columnsButton: props.enableColumnSelect,

                // tableLayout: 'fixed' fixed/auto width columns?
            }}
            // onOrderChange: function() {
            //     console.log( 'order', arguments);
            // }
            localization={{
                body: {
                    emptyDataSourceMessage: emptyMessage
                },
                pagination: {
                    labelRowsSelect: ''
                }
            }}
            actions={ getActions( actions ) }

            {...componentProps}

            // Override using https://material-table.com/#/docs/features/component-overriding
            components={{
                // Actions: props => {
                //     // console.log( 'actions', props );
                //     let newProps = props;
                //     // console.log('actions', props)
                //     newProps.actions.unshift( newProps.actions.pop() );
                //     return <>
                //         <MTableActions className="ACTIONSxxx" {...newProps} />
                //     </>
                // },

                // style with td { position:relative }, td .MuiIcon-root { position:absolute, top: 10px; right: 0}
                // Cell: function(props) {
                //     // console.log( 'cell', props );
                //     // console.log('cell ags', arguments);
                //     if ( props.rowData.tableData.id === hoverId[0] && props.columnDef.tableData.id === hoverId[1]) {
                //         let value = props.columnDef.render(props.rowData);
                //         props.columnDef.render = function(a: any,b: any){
                //             // console.log('rendy', arguments);
                //             return <>{value} <Icon>timeline</Icon></>
                //         }
                //     }
                //     // let px: any = props;
                //     return <MTableCell
                //         // colSpan={2}
                //         // rowSpan={}
                //         xyz={123}
                //         className="CELLxxx"
                //         onMouseEnter={function(e: any){
                //             // console.log('in cell', props.rowData.tableData.id, props.columnDef.tableData.id);
                //             setHoverId([props.rowData.tableData.id, props.columnDef.tableData.id]);
                //         }}
                //         // value={ props.value + ' LOL' }
                //         {...props}
                //     />
                // },
                // Header: subprops => {
                //     // className doesnt work on MTableHeader.
                //     // Default to showing header.
                //     return <div className={ ( props.enableHeader ?? true ) ? '' : 'hidden' }>
                //         <MTableHeader {...subprops} />
                //     </div>
                // },
                // Row: props => {
                //     console.log( 'row', props );
                //     return <MTableBodyRow className="ROWxxx" {...props} />
                // }
                // Toolbar: props => {
                //     console.log( 'enableT, props );
                //     return <div>
                //         123
                //         <MTableToolbar {...props} />
                //         456
                //     </div>;
                // }
            }}

            // // Override to hijack event handler and simplify calls
            // components={{
            //     Actions: props => {
            //         if ( props?.actions[0].onClick ) {
            //             console.log( 'GO' )
            //         }

            //         console.log(props)

            //       return <Button
            //       onClick={(event) => 
            //         console.log("add user", props)
            //       }}
            //     >
            //       Add New User
            //     </Button>

            //     }
            //   }}

            // What is columnsHiddenInColumnsButton?
            // components={{
            //     Toolbar: ( props: any ) => (
            //         <Grid container>
            //             <Grid item xs={6}>
            //                 {/* ( 1
            //                     ? <FilterBuilder
            //                         fields={filterFields}
            //                     />
            //                     : ''
            //                 ) */}
            //             </Grid>
            //             <Grid item xs={6}>
            //                 <MTableToolbar {...(Object.assign({columnsHiddenInColumnsButton: true},props))} />
            //             </Grid>
            //         </Grid>
            //     )
            // }}
        />
    </Paper>;
}

export { Table };
export type { Action, Column, ColumnSet, ColumnSetHash };
