
import { ComingSoon } from './components/ComingSoon';
import { Home } from './components/Home';

import { AccountsForgot } from './components/accounts/Forgot';
import { AccountsLogin } from './components/accounts/Login';
import { AccountsLogout } from './components/accounts/Logout';
import { AccountsReset } from './components/accounts/Reset';

import { SystemCache } from './components/admin/system/Cache';
import { SystemStatus } from './components/admin/system/Status';
import { ToolsBudgetCalculator } from './components/admin/tools/BudgetCalculator';
import { ToolsPacing } from './components/admin/tools/Pacing';
import { UsersList } from './components/admin/users/List';

import { ReportsView } from './components/reports/View';

// import { AdminBudgetsMinSpends } from './_old/admin/budgets/MinSpends';
// import { AdminBudgetsPacing } from './_old/admin/budgets/Pacing';
// import { AdminHealthFacebookCatalog } from './_old/admin/health/FacebookCatalog';
// import { AdminHealthFacebookCTAs } from './_old/admin/health/FacebookCTAs';
// import { AdminHealthFacebookLinkParams } from './_old/admin/health/FacebookLinkParams';
// import { AdminHealthFacebookPixel } from './_old/admin/health/FacebookPixel';
// import { AdminSetupAudiences } from './_old/admin/setup/Audiences';

import { Test } from './components/Test'; // Shim for testing
import { Route } from './hooks/useRouter';

export const routes: Route[] = [
    // Login

    {
        path: '/account/forgot',
        element: AccountsForgot,
        private: false
    },
    {
        path: '/account/reset/:code',
        element: AccountsReset,
        private: false
    },
    /* <AuthRoute component={AccountsSetup} path="/account/setup" private={false} /> */
    {
        path: '/login',
        element: AccountsLogin,
        private: false
    },
    {
        path: '/logout',
        element: AccountsLogout
    },

    // Reports

    {
        path: '/reports/:id/*',
        element: ReportsView
    },

    {
        path: '/reports/:id/:page/*',
        element: ReportsView
    },

    /* <AuthRoute component={AdminHealthFacebookCatalog} path="/admin/health/facebook/catalog" />
    <AuthRoute component={AdminHealthFacebookCTAs} path="/admin/health/facebook/ctas" />
    <AuthRoute component={AdminHealthFacebookPixel} path="/admin/health/facebook/pixel" />
    <AuthRoute component={AdminHealthFacebookLinkParams} path="/admin/health/facebook/link-params" />

    <AuthRoute component={AdminBudgetsMinSpends} path="/admin/facebook/budgets/min-spends" />
    <AuthRoute component={AdminBudgetsPacing} path="/admin/facebook/budgets/pacing" />
    <AuthRoute component={AdminSetupAudiences} path="/admin/facebook/setup/audiences" /> */

    {
        path: '/admin/system/cache',
        element: SystemCache
    },
    {
        path: '/admin/system/status',
        element: SystemStatus
    },
    {
        path: '/admin/tools/budget-calculator',
        element: ToolsBudgetCalculator
    },
    {
        path: '/admin/tools/pacing',
        element: ToolsPacing
    },
    {
        path: '/admin/users',
        element: UsersList
    },

    // Shim for testing
    {
        path: '/mtest',
        element: Test
    },

    {
        path: '/',
        element: Home
    },

    // Fallback

    {
        path: '*',
        element: ComingSoon,
        index: true
    }
];
