import { useFields } from '../../../hooks/useFields';
import { ColumnSelectTable } from '../../../components/ui/ColumnSelectTable';

export function SharedGoogleShopping() {
    const { getFields } = useFields();

    const fieldSets = getFields( [
        'ga_shopping_basic', 'ga_shopping_basic_categories',
        'ga_shopping_full', 'ga_shopping_full_categories'
    ] );

    let set = 'ga_shopping_basic';

    return <ColumnSelectTable
        name='Google Shopping'
        api='google/shopping'
        dimensionParams={{ platform: 'google' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
