import React from 'react';

import { Card } from './Card';

interface Props {
    text?: string;
    values?: any[];

    type?: string;
    precision?: number;

    // If the compare feature is on.
    compareEnabled?: boolean;

    // If the compare value should be shown or just the percentage.
    compareShowValue?: boolean;

    // If the compare value is better in the negative direction.
    compareInverted?: boolean;

    tooltip?: React.ReactNode;
}

export function CompareCard( props: Props ) {
    const values = [
        props.values?.length ? props.values[0] : null ,
        props.values?.length ? props.values[1] : null ,
    ];

    return <Card
        text={props.text}
        value={values[0]}
        type={props.type}
        precision={props.precision}

        compareEnabled={props.compareEnabled}
        compareValue={values[1]}
        compareShowValue={props.compareShowValue}
        compareInverted={props.compareInverted}

        tooltip={props.tooltip}
    />
}
