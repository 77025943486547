import { useFields } from '../../../hooks/useFields';
import { ColumnSelectTable } from '../../../components/ui/ColumnSelectTable';

export function SharedGoogleKeywords() {
    const { getFields } = useFields();

    const fieldSets = getFields( [ 'ga_keywords' ] );

    let set = 'ga_keywords';

    return <ColumnSelectTable
        name='Google Keywords'
        api='google/keywords'
        dimensionParams={{ platform: 'google' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
