import { useAuth } from './useAuth';

export function useUser() {
    const { auth } = useAuth();

    return {
        user: {
            name: auth.name,
            username: auth.username,
            initials: auth.initials
        }
    };
}
