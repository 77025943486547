import { useEffect, useState } from 'react';
import { ContextData } from '../components/Types';

import { useAccounts } from './useAccounts';
import { useApi } from './useApi';
import { useAuth } from './useAuth';
import { useDebugRenderCount } from './useDebugRenderCount';
import { useFetch } from './useFetch';
import { useFilters } from './useFilters';

export function useQuery(
    url: string,
    params: any = {},
    raw: boolean = false,
    context: ContextData = {}
) {
    const { auth } = useAuth();
    const { account, accounts } = useAccounts();
    // const { getFilter, getFilters } = useFilters();
    const { sendGet } = useApi();

    const { getMethodsForIndex } = useFilters();
    const { getFilter, getFilters } = getMethodsForIndex( context?.filterPrefix );

    
// console.log( "\n\nquery args", url, raw, params)
const renderCount = useDebugRenderCount();
// console.log( 'useQuery renders', renderCount )
// console.log( 'query accounts', accounts)
// console.log( 'query filters', context?.filterPrefix, getFilters())

    // Used to stop loading until auth and account are loaded.
    const [ shouldFetch, setShouldFetch ]: any = useState( false );

    const fetchData = async ( url: string ) => {
        let requestParams: any;

        // Pass raw params as is.
        if ( raw ) {
            requestParams = {
                u: auth.id,
                ...params
            };
        } else {
            requestParams = {
                u: auth.id,
                account: account?.id
            };

            if ( params.platform ) {
                requestParams.platform = params.platform;
            }

            // Add any allowed parameters passed.
            const allowedParams = [ 'fields' ];

            for ( let name of allowedParams ) {
                if ( params[ name ] ) {
                    requestParams[ name ] = params[ name ];
                }
            }

            // These are filters that are added to the root request instead of
            // passed in filters key.
            const rootFilters = [ 'date_start', 'date_end', 'compare_date_start', 'compare_date_end' ];

            // Add ignored filters directly.
            for ( let key of rootFilters ) {
                // Only add if they are set in filters.
                if ( getFilter( key ) ) {
                    requestParams[ key ] = getFilter( key );
                }
            }

            // Add other filters.
            requestParams.filters = {};

            for ( let key in getFilters() ) {
                // Don't add ignored filters or 'all' values.
                if ( !rootFilters.includes( key ) && getFilter( key ) !== 'all' ) {
                    requestParams.filters[ key ] = getFilter( key );
                }
            }

            // Add type as filter.
            if ( params[ 'type' ] ) {
                requestParams.filters.type = params[ 'type' ];
            }
        }

// console.log( 'api get', url, requestParams, getFilters() );
        const response = await sendGet( url, requestParams );

        return response;
    };

    // let x = Math.random();

    // Context is considered loaded if there's no prefix or there is one and the filters are available.
    // This does not happen at the same time because prefix is set via props while filters are set in useEffect. Fetching before context is loaded results in errors
    const contextLoaded = !(context?.filterPrefix) || Object.keys( getFilters() ).length > 0;

    const { data, error, isLoaded, refresh } = useFetch(
        url,
        fetchData,
        [ auth.id, url, account?.id ],
        shouldFetch,
        // shouldFetch && contextLoaded
    );

    console.log( 'usequery -> fetch?', url, ' should: ', shouldFetch, 'context: ', contextLoaded, 'fetch done:', isLoaded, new Date() );

// TODO: gate here based on whether url and params have changed. if not don't trigger refresh within 1s?
    useEffect(
        () => {
// console.log( 'UE useQuery', shouldFetch, isLoaded, error, url, params, context, data );
            // Don't make calls if we don't have data.
            if ( !auth.id || !account?.id ) {
// console.log( "\tquery no auth/account" );
                return;
            }

            if ( !contextLoaded ) {
// console.log( "\tquery no context" );
                return;
            }

            if ( !shouldFetch ) {
// console.log( "\tquery turning on fetch" );
                setShouldFetch( true );
            } else {
console.log( "\tquery refresh" );
                refresh();
            }
        },
        [ auth.id, account?.id, contextLoaded ]
        // [ auth.id, url, account.id, Object.keys( context?.filters ?? {} ).length ]
    );

    return { data, error, isLoaded, refresh };
}
