import React from 'react';
import { Icon } from '@material-ui/core';

import { useUser } from '../hooks/useUser';

export function Home() {
    const { user } = useUser();

    return <div className="page-home">
        <h1>Hi {user.name}</h1>
        <p>Click <Icon>menu</Icon> to begin.</p>
    </div>
}
