import { useEffect, useRef, useState } from 'react';
import { ObjectType, objectEquals, Nullable } from '../lib/Tools';
import { useFiltersStore } from '../state/FiltersStore';

import { useAuth } from './useAuth';
import { useDate } from './useDate';
import { useRouter } from './useRouter';

export const useFilters = ( prefix?: Nullable<string>, global = false ) => {
console.log( 'usefilters2????');
    const { params, setParams, unsetParams } = useRouter();
    const { auth } = useAuth();

    const { getDate } = useDate();

    // The default prefix is saved in URL params.
    const defaultPrefix = 'global';
    const isDefaultPrefix = prefix === defaultPrefix;

    // Set initial state to URL params on defaut prefix only.
    const initialState: any = isDefaultPrefix ? { [defaultPrefix]: params } : {} ;

    const { filters, setAllStoreFilters, setStoreFilters, setStoreFiltersAtPrefix, unsetStoreFilters } = useFiltersStore( prefix ?? defaultPrefix, initialState );

    // console.log( 'usefilters2 filters', filters );

    // Standard methods

    const clearFilters = () => {
        setAllStoreFilters( {} );

        if ( isDefaultPrefix && global ) {
            setParams({});
        }
    }

    const getFilter = ( key: string ) => {
        const all = getFilters();

        return all?.[ key ];
    }

    const getFilters = () => {
// console.log( 'getFilters', prefix, filters );
        return filters ?? {};
    }

    const setFilter = ( key: string, value: any ) => {
// console.log( 'setfilter', prefix, key, value );
        const data = { [key]: value };

        setFilters( data );

        if ( isDefaultPrefix ) {
            setParams( data );
        }
    };

    const setFilters = ( data: ObjectType ) => {
// console.log( 'setfilters', prefix, data );

        // const thisPrefix = getPrefix( prefix );

        setStoreFilters( data );

        if ( isDefaultPrefix && global ) {
            setParams( data );
        }
    }

    const setFiltersAtPrefix = ( prefix: string, data: ObjectType ) => {
        setStoreFiltersAtPrefix( prefix, data );
    }

    const unsetFilter = ( key: string ) => {
        unsetFilters( [ key ] );
    };

    const unsetFilters = ( keys: string[] ) => {
        unsetStoreFilters( keys );

        if ( isDefaultPrefix && global ) {
            unsetParams( keys );
        }
    };

    // Create ref to track last value.
    const lastValue: any = useRef();

    useEffect( () => {
// console.log('filters2 UE', filters, lastValue.current );

        // If default index has changed, set URL params.
        if ( isDefaultPrefix && global && !objectEquals( filters, lastValue.current ) ) {
            setParams( filters );
        }

        // Set last value to check for changes later.
        lastValue.current = filters;

        const now: number = Date.now();

        // Collect updates in array and update them all at once. This may no be necessary with React 18.
        let newData: { [ key: string ]: string } = {};

        if ( !filters.date_start ) {
            newData[ 'date_start' ] = getDate( 'Y-m-d', new Date( now - 30 * 86400 * 1000 ) );
        }

        if ( !filters.date_end ) {
            newData[ 'date_end' ] = getDate( 'Y-m-d', new Date( now - 86400 * 1000 ) );
        }

        if ( !filters.view ) {
            // setFilter( 'view', auth.level );
            newData[ 'view' ] = auth.level;
        }

        // TODO: WHY is this even there?
        // if ( auth.level !== filters.view ) {
        //     newData[ 'view' ] = auth.level;
        // }
        // setFilter( 'view', auth.level );

        // Set data at once to avoid multiple re-renders.
        if ( Object.keys( newData ).length ) {
// console.log( 'new filters', newData);
            setFilters( newData );
        }
// console.log( 'filters UE', filters );
    // }, [ auth.level, filters.date_start, filters.date_end, location ] );
    }, [ auth.level, filters ] );

    return {
        getFilter,
        getFilters,
        setFilter,
        setFilters,
        setFiltersAtPrefix,
        unsetFilter,
        unsetFilters,
        clearFilters
    };
};
