// import { FacebookBudgets } from '../../../components/facebook/Budgets';

import { Route, RouteHash } from '../../hooks/useRouter';

import { SharedFacebookAds } from './facebook/Ads';
import { SharedFacebookAudiences } from './facebook/Audiences';
import { SharedFacebookCampaigns } from './facebook/Campaigns';
import { SharedFacebookCreatives } from './facebook/Creatives';
import { SharedFacebookPacing } from './facebook/Pacing';
import { SharedFacebookSummary } from './facebook/Summary';
import { SharedGoogleAds } from './google/Ads';
import { SharedGoogleCampaigns } from './google/Campaigns';
import { SharedGoogleKeywords } from './google/Keywords';
import { SharedGooglePacing } from './google/Pacing';
import { SharedGoogleShopping } from './google/Shopping';
import { SharedGoogleSummary } from './google/Summary';
import { SharedGoogleTerms } from './google/Terms';
import { ShopifyOrders } from './shopify/Orders';
import { ShopifySummary } from './shopify/Summary';
import { TikTokCampaigns } from './tiktok/Campaigns';
import { TikTokCreatives } from './tiktok/Creatives';
import { TikTokSummary } from './tiktok/Summary';

const defaultRoutes: RouteHash = {
    'facebook': [
        {
            path: 'facebook/ads',
            element: SharedFacebookAds,
        },
        {
            path: 'facebook/audiences',
            element: SharedFacebookAudiences,
        },
        {
            path: 'facebook/campaigns',
            element: SharedFacebookCampaigns,
        },
        {
            path: 'facebook/creatives',
            element: SharedFacebookCreatives,
        },
        {
            path: 'facebook/summary',
            element: SharedFacebookSummary,
        },
        {
            path: 'facebook/pacing',
            element: SharedFacebookPacing
        },
    ],

    'google': [
        {
            path: 'google/ads',
            element: SharedGoogleAds,
        },
        {
            path: 'google/campaigns',
            element: SharedGoogleCampaigns,
        },
        {
            path: 'google/keywords',
            element: SharedGoogleKeywords,
        },
        {
            path: 'google/pacing',
            element: SharedGooglePacing,
        },
        {
            path: 'google/shopping',
            element: SharedGoogleShopping,
        },
        {
            path: 'google/summary',
            element: SharedGoogleSummary,
        },
        {
            path: 'google/terms',
            element: SharedGoogleTerms
        },
    ],
    
    'shopify': [
        {
            path: 'shopify/orders',
            element: ShopifyOrders,
        },
        {
            path: 'shopify/summary',
            element: ShopifySummary
        },
    ],

    'tiktok': [
        {
            path: 'tiktok/campaigns',
            element: TikTokCampaigns,
        },
        {
            path: 'tiktok/creatives',
            element: TikTokCreatives,
        },
        {
            path: 'tiktok/summary',
            element: TikTokSummary
        },
    ]
}

const routeLists = Object.values( defaultRoutes );

let routes: Route[] = [];

for ( let routeList of routeLists ) {
    routes = routes.concat( routeList );
}

export { routes, defaultRoutes };
