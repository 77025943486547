import React, {Fragment, useState } from 'react';
import { Paper, Tab, Tabs as BaseTabs } from '@material-ui/core';

interface TabsProps {
    children: React.ReactNode | any;
}

interface TabsContentProps {
    name: string;
    children: React.ReactNode | any;
}

function Tabs( props: TabsProps ) {
    let [ tab, setTab ] = useState(0);

    // If there is only one tab, props.children won't be an array. We need an
    // array to use map().
    const children = React.Children.toArray( props.children );

    return <div className="tabs">
        <Paper className="tab-labels">
            <BaseTabs value={tab} centered>
                { children.map( ( item: any, i: number ) => <Tab key={i} label={item.props.name} onClick={ () => setTab(i) } /> ) }
            </BaseTabs>
        </Paper>

        <Paper className="tab-content">
            <div className="content">
                { children.map( ( item: any, i: number ) => {
                    if ( i === tab ) {
                        return <Fragment key={i}>{item}</Fragment>;
                    }

                    return null;
                } ) }
            </div>
        </Paper>
    </div>
}

function TabsContent( props: TabsContentProps ) {
    return <Fragment>
        {props.children}
    </Fragment>
}

export {
    Tabs,
    TabsContent
}