const defaultMenus = {
    // Paid

    facebook: {
        name: 'Facebook',
        icon_svg: 'facebook',
        platform: 'facebook',
        // permission: 'facebook',
        children: [
            {
                name: 'Summary',
                icon: 'format_list_bulleted',
                link: 'facebook/summary',
            },
            {
                name: 'Campaigns',
                icon: 'opacity',
                link: 'facebook/campaigns'
            },
            {
                name: 'Audiences',
                icon: 'people',
                link: 'facebook/audiences',
                view: 'user'
            },
            {
                name: 'Ads',
                icon: 'crop_original',
                link: 'facebook/ads',
                view: 'user'
            },
            {
                name: 'Creatives',
                icon: 'visibility',
                link: 'facebook/creatives',
            },

            {
                name: 'Pacing',
                icon: 'speed',
                link: 'facebook/pacing',
                view: 'user'
            },

            {
                name: 'Health',
                icon: 'local_hospital',
                view: 'admin',
                children: [
                    {
                        name: 'Catalog',
                        icon: 'menu_book',
                        link: 'facebook/health/catalog'
                    },
                    {
                        name: 'Pixel',
                        icon: 'adjust',
                        link: 'facebook/health/pixel'
                    },
                    {
                        name: 'Link Params',
                        icon: 'link',
                        link: 'facebook/health/link-params'
                    },
                    {
                        name: 'CTAs',
                        icon: 'call_to_action',
                        link: 'facebook/health/ctas'
                    },
                    // {
                    //     name: 'Descriptions',
                    //     icon: 'text_format',
                    //     link: 'facebook/health/descriptions',
                    //     disabled: true
                    // }
                ]
            }
        ]
    },

    google: {
        name: 'Google',
        icon_svg: 'google',
        platform: 'google',
        // permission: 'google',
        children: [
            {
                name: 'Summary',
                icon: 'format_list_bulleted',
                link: 'google/summary',
            },
            {
                name: 'Campaigns',
                icon: 'opacity',
                link: 'google/campaigns'
            },
            {
                name: 'Audiences',
                icon: 'people',
                link: 'google/audiences',
                view: 'admin'
            },
            {
                name: 'Ads',
                icon: 'crop_original',
                link: 'google/ads',
                view: 'user'
            },
            {
                name: 'Shopping',
                icon: 'shopping_basket',
                link: 'google/shopping',
            },
            {
                name: 'Terms',
                icon: 'search',
                link: 'google/terms',
            },
            {
                name: 'Keywords',
                icon: 'translate',
                link: 'google/keywords',
            },
            {
                name: 'Pacing',
                icon: 'speed',
                link: 'google/pacing',
                view: 'user'
            }
        ]
    },

    tiktok: {
        name: 'Tiktok',
        icon_svg: 'tiktok',
        platform: 'tiktok',
        // permission: 'tiktok',
        children: [
            {
                name: 'Summary',
                icon: 'format_list_bulleted',
                link: 'tiktok/summary',
            },
            {
                name: 'Campaigns',
                icon: 'opacity',
                link: 'tiktok/campaigns'
            },
            {
                name: 'Audiences',
                icon: 'people',
                link: 'tiktok/audiences',
                view: 'admin'
            },
            {
                name: 'Ads',
                icon: 'crop_original',
                link: 'tiktok/ads',
                view: 'admin'
            },
            {
                name: 'Creatives',
                icon: 'visibility',
                link: 'tiktok/creatives',
            }
        ]
    },

    // Email

    reporting: {
        eroas: {

        }
    },

    // Stores

    shopify: {
        name: 'Shopify',
        icon_svg: 'shopify',
        platform: 'shopify',
        children: [
            {
                name: 'Summary',
                icon: 'format_list_bulleted',
                link: 'shopify/summary',
            },
            {
                name: 'Orders',
                icon: 'redeem',
                link: 'shopify/orders',
            }
        ]
    },



    // klaviyo: {
    //     name: 'Klaviyo',
    //     icon: 'drafts',
    //     // icon_svg: 'M25 315.8l91.8-60.8c4-2.3 8.9-2.3 12.9 0l91.8 60.8c3.6 1.8 5.2 6.1 3.4 9.8c-0.7 1.5-1.9 2.7-3.4 3.4 l-12.3 8.1c-30.1-47.5-92.9-61.6-140.4-31.6c-12.7 8.1-23.5 18.8-31.6 31.6L24.8 329c-3.6-1.9-5-6.3-3.2-9.9 C22.4 317.7 23.6 316.5 25 315.8z M123.1 315.1c-26.3 0.1-50.8 13.6-64.8 35.9l21.1 14c14.5-24.1 45.8-31.8 69.8-17.3 c7.1 4.3 13 10.2 17.3 17.3l21.1-14C173.8 328.6 149.4 315 123.1 315.1z M123.1 365.9c-9.3 0.1-17.8 5.1-22.4 13.2l14.2 9.3 c2.3 2 5.2 3 8.2 3s6-1.1 8.2-3l14.2-9.3C141 370.9 132.4 365.9 123.1 365.9z',
    //     // permission: 'email',
    //     // disabled: true
    //     children: [ {} ]
    // },

    // ga: {
    //     name: 'Google Analytics',
    //     icon: 'equalizer',
    //     // permission: 'google',
    //     // disabled: true
    //     children: [ {} ]
    // },

    // magento: {
    //     name: 'Magento',
    //     icon_svg: 'magento',
    //     // permission: 'magento',
    //     // disabled: true
    //     children: [ {} ]
    // },

    // snapchat: {
    //     name: 'Snapchat',
    //     icon_svg: 'snapchat',
    //     // permission: 'snapchat',
    //     // disabled: true
    //     children: [ {} ]
    // },
    // pinterest: {
    //     name: 'Pinterest',
    //     icon_svg: 'pinterest',
    //     // permission: 'pinterest',
    //     // disabled: true
    //     children: [ {} ]
    // }
};

const menus = Object.values( defaultMenus );

export { menus, defaultMenus };
