import { TabCards } from '../../../components/ui/TabCards';
import { useFields } from '../../../hooks/useFields';

export function KissReportingNails() {
    const { getFieldsFromTabCards, getLayout } = useFields();

    const tabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend' ],
                [ 'impressions', 'reach', 'cpm' ],
                [ 'post_engagements', 'engagement_rate', 'cost_per_post_engagement' ],
                [ 'clicks', 'ctr', 'link_clicks', 'link_ctr' ],
                [ 'revenue', 'roas_float' ]
            ] )
        }
    ];

    return <TabCards
        title='Nails Reporting'
        api='facebook/summary/awareness'
        params={{ fields: getFieldsFromTabCards(tabs).join( ',' ) }}
        dimensionParams={{ platform: 'facebook', type: 'awareness' }}
        tabs={tabs}
        enableCompare={true}
        enableSave={true}
    />
}
