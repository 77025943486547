// @date-io/date-fns is use by @material-ui/pickers but does not include the entire date-fns interface. It can be removed later.
// import * as DateFns from 'date-fns';
import { addDays, differenceInDays, getDaysInMonth, intervalToDuration } from 'date-fns';
import { format } from 'date-fns-tz';
// potentially remove extras
// import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

type AmorphousDate = Date | number | string;

interface DateRange {
    start: string,
    end: string
}

// Map date format tokens to match backend.
// PHP https://www.php.net/manual/en/datetime.format.php
// Library https://date-fns.org/v2.28.0/docs/format
const mappings: any = {
    'a': 'aaa',
    'A': 'aa',
    'd': 'dd',
    'D': 'EEE',

    // From date-fns-tz
    'e': 'zzz', 

    // From date-fns-tz. Created to mirror 'e'
    'E': 'zzzz', 

    'F': 'MMMM',
    'g': 'h',
    'G': 'H',
    'h': 'hh',
    'H': 'HH',
    'i': 'mm',
    'j': 'd',
    'l': 'EEEE',
    'm': 'MM',
    'M': 'MMM',
    'n': 'M',
    'N': 'i',
    'O': 'xx',
    'p': 'XXX',
    'P': 'xxx',
    's': 'ss',
    'S': 'do',
    // This is done manually.
    // 't': '',
    // 'T': '',
    'U': 't',
    // 'w': '',
    'W': 'I',
    'y': 'yy',
    'Y': 'yyyy',
    // Note: z is 0-365, but D is 1-366. When using 'D', React complains to use 'd' instead.
    // 'z': 'd',
    // 'Z': '',
}

const addDaysNew = ( date: AmorphousDate, amount: number ): Date => {
    return addDays( parse( date ), amount );
}

const getDate = ( dateFormat: string, value: AmorphousDate | null = null ): string => {
// console.log( 'getDate', dateFormat, value )
    // S (day suffix ex: st, nd, rd) does not exist by itself. So translate backend jS to S to get the same result.
    dateFormat = dateFormat.replace( /jS/g, 'S' );

    if ( value === null ) {
        value = new Date();
    }

    let dateValue: Date = new Date( value );

    // t is not supported by library but is a common operation.
    if ( dateFormat.match( 't' ) ) {
        const daysInMonth = getDaysInMonth( dateValue );

        dateFormat = dateFormat.replace( /t/g, daysInMonth.toString() );
    }

    // Map format.
    let tokens = dateFormat.split( '' );

    for ( let i in tokens ) {
        if ( mappings[ tokens[ i ] ] ) {
            tokens[i] = mappings[ tokens[ i ] ];
        }
    }

    const mappedFormat = tokens.join( '' );

    // Adjust date by timezone offset to UTC to prevent in browser offsets messing up times.
    const adjustedDate = new Date( dateValue.getTime() + dateValue.getTimezoneOffset() * 60000);
console.log(
    "\n\ndate: ", dateFormat, value, "\n",
)
console.log(
    format( dateValue, mappedFormat ), "\n",
    format( adjustedDate, mappedFormat )
);
    // return format( dateValue, mappedFormat );
    return format( adjustedDate, mappedFormat );
    // return formatInTimeZone( adjustedDate, 'UTC', mappedFormat );
}

const getDifference = ( start: AmorphousDate, end: AmorphousDate ): Duration => {
    const interval = {
        start: parse( start ),
        end: parse( end )
    };

    return intervalToDuration( interval );
}

const getDifferenceInDays = ( start: AmorphousDate, end: AmorphousDate ): number => {
    // If start < end, this will be negative. Return a positive value because that is more often needed.
    return Math.abs( differenceInDays( parse( start ), parse( end ) ) );
}

const parse = ( date: AmorphousDate ): Date => {
    return new Date( date );
}

export const useDate = () => {
    const timezoneOffset = (new Date()).getTimezoneOffset() * 60 * 1000;

    return {
        addDays: addDaysNew,
        getDate,
        getDifference,
        getDifferenceInDays,
        timezoneOffset
    };
}

export type { DateRange };

// declare var window: any; window.getDate = getDate;