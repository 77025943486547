import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAccounts } from '../../hooks/useAccounts';

import { useAuth } from '../../hooks/useAuth';

export function AccountsLogout() {
    const { setAuth } = useAuth();
    const { setAccount } = useAccounts();

    useEffect( () => {
        setAuth( null );
        setAccount( null );
    }, [ setAccount, setAuth ] );

    return <Navigate to={{ pathname: '/' }} />;
}
