import { Container } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import axios from 'axios';
import React, { Fragment } from 'react';

import { AuthRoute } from './components/AuthRoute';
import { LoadedContent } from './components/ui/LoadedContent';
import { Menu } from './components/Menu';
import { Route } from './hooks/useRouter';
import { routes as clientRoutes } from './clients';
import { routes as systemRoutes } from './Routes';
import { useAccounts } from './hooks/useAccounts';
import { useAuth } from './hooks/useAuth';
import { useBranding } from './hooks/useBranding';

import './vendor/slick/slick.css';
import './vendor/slick/slick-theme.css';

import { SharedFacebookSummary } from './clients/shared/facebook/Summary';

// Use different port for local development because backend and frontend aren't
// connected like in prod.
if ( window.location.host.match( /(localhost|\.test):3000/ ) ) {
    axios.defaults.baseURL = window.location.origin.replace( '3000', '8335' ) + '/api/';
} else {
    axios.defaults.baseURL = '/api';
}

export function App() {
    const { isLoggedIn } = useAuth();

    const { loaded: isBrandingLoaded, error: brandingError } = useBranding();
    const { account, loaded: isAccountsLoaded, error: accountsError } = useAccounts();

    const currentRoutes: Route[] = clientRoutes[ account?.code ?? '' ] ?? [] ;

    const routes = [
        ...currentRoutes.map(
            ( route ) => {
                const Component: any = route.element;

                return {
                    ...route,
                    element: <AuthRoute {...route}><Component /></AuthRoute>
                    // element: <AuthRoute path={path}><Test init={{a: 'apple', b:'berry'}} /></AuthRoute>
                }
            }
        ),
        ...systemRoutes.map(
            ( route ) => {
                const Component: any = route.element;

                return {
                    ...route,
                    element: <AuthRoute {...route}><Component /></AuthRoute>
                }
            }
        )
    ];

    const element = useRoutes( routes );
console.log( 'app routes: ', account?.name, routes.length, currentRoutes.length, systemRoutes.length)
    return <LoadedContent
        loaded={isBrandingLoaded && isAccountsLoaded}
        error={brandingError ? brandingError : accountsError ? accountsError : ''}
        footer={isLoggedIn}
    >
        <div className="full-height">
            <>
                { isLoggedIn ? <Menu /> : '' }
                <Container maxWidth="lg" className="full-height content">
                    {element ?? ''}
                </Container>
            </>
        </div>
    </LoadedContent>;
}
