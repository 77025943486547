import { Button, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import { useApi } from '../../hooks/useApi';

import { LoginForm } from '../ui/LoginForm';

export function AccountsReset() {
    const { sendPost } = useApi();

    // Check signature.
    const { code } = useParams();

    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );

    const [ error, setError ] = useState( '' );
    const [ message, setMessage ] = useState( '' );

    const onSubmit = async () => {
        try {
            // Clear when submitting to give feedback something changed.
            setMessage( '' );
            setError( '' );

            const response = await sendPost( 'account/reset', { email, password, code } );

            setMessage( response.message );
        } catch ( error: any ) {
            setError( error.message );
        }
    };

    return <LoginForm error={error} onSubmit={onSubmit}>
        <h1>Reset Your Password</h1>

        { message ? <p>{message} Go to <a href="/login">login</a>.</p> : '' }

        <div className={ message ? 'obscured' : '' }>
            <div>
                <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={ event => setEmail( event.target.value ) }
                />
            </div>
            <div>
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={ event => setPassword( event.target.value ) }
                />
            </div>
            <div>
                <Button variant="contained" type="submit">Reset Password</Button>
                <p><a href="/login">Back to Login</a></p>
            </div>
        </div>
    </LoginForm>;
}
