import { AppBar, Avatar, Collapse, Container, Divider, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Toolbar } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';

import { Loading } from './Loading';
import { useBranding } from '../hooks/useBranding';
import { useAuth } from '../hooks/useAuth';

import { useMenu } from '../hooks/useMenu';
import { useUser } from '../hooks/useUser';
import { useAccounts } from '../hooks/useAccounts';
import { useFilters } from '../hooks/useFilters';

export function Menu() {
    const location = useLocation();

    const { branding, loaded: brandingLoaded } = useBranding();
    const { menu } = useMenu();
    const { user } = useUser();
    const { getFilter, getFilters, unsetFilters } = useFilters();

    const { canView, hasAccess, hasPermission } = useAuth();
    const [ mainMenuOpen, setMainMenuOpen ] = useState( false );
    const [ subMenuOpen, setSubMenuOpen ]: any = useState( {} );
    const { account } = useAccounts();

    useEffect( () => {}, [ account ]);

    if ( !brandingLoaded ) {
        return <Loading />;
    }

    const renderMenuItem = ( item: any, i: any ) => {
        if ( !Object.keys( item ).length ) {
            return <Divider key={i} />;
        }

        const platformMissing = item.platform && account?.platforms?.indexOf( item.platform ) === -1;

        if (
            ( item.level && !hasAccess( item.level ) )
            || ( item.view && !canView( getFilter( 'view' ), item.view ) )
            || ( item.permission && !hasPermission( account?.code ?? '', item.permission ) )
        ) {
            return '';
        }

        let link: any = {
            to: item.link ? '/' + item.link : ''
        };

        if ( item.link && location.pathname === '/' + item.link ) {
            link.selected = true;
        }

        // Disable if platform is missing also.
        if ( item.disabled || platformMissing ) {
            link.disabled = true;
        }

        if ( item.children?.length && subMenuOpen[ i ] ) {
            link.className = 'open';
        }

        // Don't add any interactivity for missing platforms.
        if ( !platformMissing ) {
            // Close links when clicked with no children.
            if ( !item.children?.length ) {
                link.onClick = ( event: Event ) => {
                    setMainMenuOpen( false );

                    // Reset extra filters when switching pages to avoid lingering
                    // filters causing no data to return.
                    const standardFilters = [ 'area', 'date_end', 'date_start', 'view' ];
                    const extraFilters = Object.keys( getFilters() ).filter(
                        (code: string): boolean => !standardFilters.includes( code )
                    );

                    unsetFilters( extraFilters );

                    if ( item.autoclose ) {
                        // Get parent by going up back one separator.
                        let parent = i.replace( /-\d+$/, '' );

                        setSubMenuOpen( {
                            ...subMenuOpen,
                            [parent]: false
                        } );    
                    }

                    if ( item.onClick ) {
                        event.preventDefault();
                        item.onClick();
                    }
                };
            } else {
                // Open menu when there's children.
                link.onClick = ( event: Event ) => {
                    event.preventDefault();
                    setSubMenuOpen( {
                        ...subMenuOpen,
                        [i]: !subMenuOpen[ i ]
                    } );
                };
            }
        }

        return <Fragment key={i}>
            <ListItem component={Link} {...link}>
                <ListItemIcon>
                    { item.icon
                        ? <Icon>{item.icon}</Icon>
                        : <SvgIcon><path d={item.icon_svg} /></SvgIcon>
                    }
                </ListItemIcon>
                <ListItemText>{item.name}</ListItemText>

                { ( !item.children?.length || platformMissing ) ? '' : ( subMenuOpen[ i ] ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon> ) }
            </ListItem>

            { ( !item.children?.length || platformMissing ) ? '' :
                <Collapse in={subMenuOpen[ i ]} unmountOnExit>
                    <List>
                        { item.children.map(
                            (child: any, j: number) => renderMenuItem( child, i+'-'+j )
                        ) }
                    </List>
                </Collapse>
            }
        </Fragment>
    }

    return <AppBar position="static" className="appbar">
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={6} className="left">
                    <Toolbar>
                        <a href="/"><img src={branding.logo_client_dark} alt={branding.client} className="logo" /></a>
                    </Toolbar>
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Toolbar className="menu">
                                <Avatar>{user.initials}</Avatar>
                                <div className="name">{user.name}</div>
                                <Icon fontSize="large" onClick={ () => setMainMenuOpen( true ) } >menu</Icon>
                            </Toolbar>

                            <Drawer
                                className="side-menu"
                                open={mainMenuOpen}
                                anchor="right"
                                onClose={ () => { setMainMenuOpen( !mainMenuOpen ) } }
                            >
                                <List>
                                    { menu.map( ( item: any, i: number ) => renderMenuItem( item, i ) ) }
                                </List>
                            </Drawer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </AppBar>;
}
