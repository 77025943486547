import React, { Fragment } from 'react';
import { Alert } from '@material-ui/lab';

import { Footer } from '../Footer';
import { Loading } from '../Loading';

interface Props {
    loaded: boolean;
    error?: string | null;
    footer?: boolean;

    children: React.ReactNode | any;
}

export function LoadedContent( props: Props ) {
    if ( !props.loaded && !props.error ) {
        return <Loading />;
    }

    if ( props.error ) {
        return <Alert severity="error">{props.error}</Alert>;
    }

    return <>
        {props.children}
        { props.footer ? <Footer /> : '' }
    </>;
}
