/**
 * Query params are shortened to params since they're more common. Router params are referred to as routeParams.
*/
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { createStore } from 'reusable';

interface Object {
    [ key: string ]: any;
}

interface Route {
    path: string;
    element: ReactNode;
    // element: any;
    private?: boolean;
    index?: boolean;
}

interface RouteHash {
    [ key: string ]: Route[];
}

// TODO: add to tools
const getObjectFromParams = ( params: URLSearchParams ) => {
    let object: any = {};

    for ( let [key, value] of params.entries() ) {
        object[ key ] = value;
    }

    return object;
}

// TODO: add to tools
// Convert boolean text values to real booleans.
const parseParams = ( params: Object ): Object => {
    let parsed: Object = {};

    for ( let [ key, value ] of Object.entries( params ) ) {
        if ( value === 'true' ) {
            value = true;
        } else if ( value === 'false' ) {
            value = false;
        }

        parsed[ key ] = value;
    }

    return parsed; 
}

export const useRouter = createStore( () => {
    const location = useLocation();

    const [ params, setAllParams ] = useSearchParams({});

    // Must be defined here due to use of params variable.
    const getParam = ( key: string ) => { 
        return params.get( key );
    }

    const setParam = ( key: string, value: string ) => {
        params.set( key, value );

        setAllParams( params );
    }

    // Adds to original params.
    const setParams = ( newParams: { [ key: string ]: string } ) => {
        for ( let key of Object.keys( newParams ) ) {
            params.set( key, newParams[ key ] );
        }

        setAllParams( params );
    }

    const unsetParam = ( key: string ) => {
        params.delete( key );

        setAllParams( params );
    }

    const unsetParams = ( keys: string[] ) => {
        for ( let key of keys ) {
            params.delete( key );
        }

        setAllParams( params );
    }

    // Rename to *UrlParams
    return {
        getParam,
        // getParams,
        location,
        // Format params for app.
        params: parseParams( getObjectFromParams( params ) ),
        setParam,
        setParams,
        setAllParams,
        unsetParam,
        unsetParams
    };
} );

export type { Route, RouteHash };
