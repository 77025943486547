import { createStore } from 'reusable';
import { useEffect, useState } from 'react';

import { useApi } from './useApi';
import { useAuth } from './useAuth';
import { useFilters } from './useFilters';
import { useSession } from './useSession';

export interface Account {  
    id: number;
    name: string;
    code: string;
    platforms: string[];
}

export type AccountId = number | null;

export const useAccounts = createStore( () => {
    const { sendGet } = useApi();
    const { auth } = useAuth();

    const { getFilter, setFilter, unsetFilter } = useFilters();

    const { getSession, setSession, unsetSession } = useSession();

    const [ accounts, setAccounts ]: [ Account[], Function ] = useState( [] );
    // const [ account, setCurrentAccountId ]: any = useState( {} );
    const [ currentAccountId, setCurrentAccountId ]: [ AccountId, Function ] = useState( null );

    const [ error, setError ] = useState( '' );
    const [ loaded, setLoaded ] = useState( false );

    const getAccount = ( id: AccountId | string ): Account | null => {
        const results = accounts.filter( ( account: Account ) => account.id === Number( id ) );

        if ( results.length ) {
            return results[0];
        }

        return null;
    }

    const setAccountById = ( id: AccountId ) => {
        const account = getAccount( id );

        if ( account ) {
            setCurrentAccountId( account.id );
        }
    }

    // When setting account, set it in state, session and URL.
    const setAccountFully = ( id: AccountId ) => {
        const account = getAccount( id );

        // Use account instead of id directly so that it forces an existence check.

        // Set in session.
        setSession( 'account', account?.id );

        // Set in URL.
        setFilter( 'account', account?.id );

        // Set in state.
        setCurrentAccountId( account?.id );
    }

    useEffect( () => {
console.log( 'accounts UE', accounts, currentAccountId, loaded, error );
        (async () => {
            // Don't set if we already loaded
            if ( loaded ) {
                return;
            }

            try {
                // If we have no auth, clear account data in state, session and URL.
                if ( !auth.id ) {
                    // Clear in session.
                    unsetSession( 'account' );

                    // Clear in URL.
                    unsetFilter( 'account' );

                    // Clear in state.
                    setAccounts( [] );

                    setLoaded( true );
                    return;
                }

                // Load account list.
                let data: Account[] = await sendGet( 'accounts', { u: auth.id } );

                // Save account list.
                setAccounts( data );

                setLoaded( true );

                // Auto select the saved account when loading.
                // Load from URL then session so that links will always load the correct account.
                const savedAccountId = getFilter( 'account' ) ?? getSession( 'account' ) ?? null;

                if ( savedAccountId ) {
                    // Find in the list.
                    const list = data.filter( ( item: Account ) => item.id === Number( savedAccountId ) );

                    if ( list.length ) {
                        setAccountFully( list[0].id );
                        
                        // setLoaded( true );
                        return;
                    }
                }

                // If we made it here, there is no saved account or it's not in the returned list.

                // Default to the first account. This is required for lists of
                // 1 account because we don't show the selection UI when there's
                // only one to choose from.
                if ( data.length ) {
                    setAccountFully( data[0].id );

                    // setLoaded( true );
                }
            } catch ( error: any ) {
                setError( `Unable to load accounts: ${error.message}` );

                setLoaded( true );
            }
        })();
    // }, [ auth.id ]);
    }, [ auth.id, accounts, currentAccountId, loaded, error ]);
// }, [ loaded, auth.id ]);

    const getMethodsForIndex = ( prefix?: string ) => {
        return {
            accounts,
            // account: getAccount( currentAccountId, prefix ),
            account: getAccount( currentAccountId ),
            setAccount: setAccountFully,
            loaded,
            error
        }
    }

    return {
        accounts,
        account: getAccount( currentAccountId ),
        getMethodsForIndex,
        setAccount: setAccountFully,
        loaded,
        error
    };
} );
