import { Paper } from '@material-ui/core';
import { useEffect } from 'react';

// import { useAccounts } from '../../hooks/useAccounts';
// import { useAuth } from '../../hooks/useAuth';
import { useFilters } from '../../hooks/useFilters';
import { useQueryDimensions } from '../../hooks/useQueryDimensions';
import { ContextProps } from '../Types';

import { DatedContent } from './DatedContent';
import { Select } from './Select';

interface Dimension {
    name: string;
    code: string;
    all?: boolean;
    default?: string;
}

// interface ListDimension extends Dimension {
//     values: string[];
// }

// interface MapDimension extends Dimension {
//     values: { [ key: string ]: string };
// }

interface Props extends ContextProps {
    title: string;
    api: string;

    params?: Object;
    dimensionParams?: Object;

    enableCompare?: boolean;
    enableDates?: boolean;
    enableSave?: boolean;

    filters?: Dimension[];

    render: (data: any, options: any) => JSX.Element;
}

export function FilteredDatedContent( props: Props ) {
    // const { canView } = useAuth();
    // const { account } = useAccounts();

    const { getMethodsForIndex } = useFilters();
    const { getFilter, setFilter } = getMethodsForIndex( props.context?.filterPrefix );
// const { getFilter, setFilter, getFilters } = getMethodsForIndex( props.context?.filterPrefix );

// console.log( 'FilteredDatedContent', props.context?.filterPrefix, getFilters() )

    let params: any = props.params ?? {} ;

    let dimensionParams = { ...params, ...( props.dimensionParams ?? {} ) };

    // Don't pass unnecessary fields to dimensionParams.
    delete dimensionParams[ 'fields' ];

    let { dimensions, dimensionsRefresh, dimensionsError, dimensionsLoaded } = useQueryDimensions( dimensionParams );

    // Make sure dimensions are loaded.
    if ( dimensions ) {
        // Filter out system dimensions.
        dimensions = dimensions.filter( (item: any) => {
            if ( item.code === 'type' ) {
                return false;
            }

            return true;
        });

        // Won't this not run if there is no dimension?
        // Add passed dimensions.
        if ( props.filters?.length ) {
            for ( let filter of props.filters ) {
                dimensions.push( filter );

                // Set default in filters if it has no value.
                if ( filter.default && !getFilter( filter.code ) ) {
                    setFilter( filter.code, filter.default );
                }
            }
        }
    }

    // useEffect( () => {
    //     if ( dimensions ) {
    //         // Won't this not run if there is no dimension?
    //         // Add passed dimensions.
    //         if ( props.filters?.length ) {
    //             for ( let filter of props.filters ) {
    //                 dimensions.push( filter );

    //                 // Set default in filters if it has no value.
    //                 if ( filter.default && !getFilter( filter.code ) ) {
    //                     setFilter( filter.code, filter.default );
    //                 }
    //             }
    //         }
    //     }
    // } );

    // Default to show dates.
    // const showDates = props.enableDates ?? true ;

    const renderFilters = () => {
        return dimensions?.length
            ? <Paper>
                { dimensions.map(
                    ( item: any, i: number ) => <Select
                        key={i}
                        id={item.code}
                        text={item.name}
                        items={ item.list.map( ( name: string ) => { return { text: name, value: name }; } ) }
                        value={ getFilter( item.code ) }

                        // Show All if it's not set or if it's set to true
                        showAll={ item.all === undefined || item.all }

                        onChange={ ( field: string, value: string ) => setFilter( field, value ) }
                    />
                ) }
            </Paper>
            : <></>
        ;
    }

    return <DatedContent
        title={props.title}
        api={props.api}
        params={props.params}
        enableCompare={props.enableCompare}
        enableSave={props.enableSave}

        renderContent={props.render}

        topbarLoaded={dimensionsLoaded}
        topbarError={dimensionsError}
        topbarRefresh={dimensionsRefresh}
        renderTopbar={renderFilters}

        context={props.context}
    />
}
