import { useState } from 'react';
import {  Button, TextField } from '@material-ui/core';

import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';

import { LoginForm } from '../ui/LoginForm';

export function AccountsLogin() {
    const { sendPost } = useApi();
    const { setAuth } = useAuth();

    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ error, setError ] = useState( '' );

    const onSubmit = async () => {
        try {
            // Clear when submitting to give feedback something changed.
            setError( '' );

            const response = await sendPost( 'account/login', { email, password } );

            setAuth( response );
        } catch ( error: any ) {
            setError( error.message );
        }
    };

    return <LoginForm error={error} onSubmit={onSubmit}>
        <div>
            <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={ event => setEmail( event.target.value ) }
            />
        </div>
        <div>
            <TextField
                label="Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={ event => setPassword( event.target.value ) }
            />
        </div>
        <div>
            <Button variant="contained" type="submit">Log In</Button>
            <p><a href="/account/forgot">Forgot password?</a></p>
        </div>
    </LoginForm>;
}
