import { Grid } from '@material-ui/core';

import { Tabs, TabsContent } from './Tabs';
import { CompareCard } from './CompareCard';
import { FilteredDatedContent } from './FilteredDatedContent';
import { ContextProps } from '../Types';

interface Props extends ContextProps {
    title: string;

    api: string;
    params?: Object;
    dimensionParams?: Object;

    enableCompare?: boolean;
    enableSave?: boolean;

    tabs: TabCardType[];

    // filters?: Dimension[];
    // render: (data: any, options: any) => JSX.Element;
}

interface Card {
    name: string;
    field: string;
    type?: CardType;
    precision?: number;
}

// interface CardDisplayOptions {
//     type?: CardType;
//     precision?: number;
// }

type CardType = 'money' | 'number' | 'percent';

export interface TabCardType {
    name: string,
    cards: Card[][]
}

function TabCards( props: Props ) {
    const render = ( data: any, options: any ) => {
        const cardOptions = {
            compareEnabled: options?.compare?.enabled,
            compareShowValue: options?.compare?.showValue
        };

        const getPath = ( object: any, path: string ) => {
            // We can't access object properties on non-objects.
            if ( !( object instanceof Object ) ) {
                return null;
            }

            // Split potential multi-key paths for looping.
            let paths = path.split( /\./ );

            // Store current reference.
            let node = object;

            for ( let subpath of paths ) {
                // If the path doesn't exist, we can't continue.
                if ( node[ subpath ] === undefined ) {
                    return null;
                }

                node = node[ subpath ];
            }

            // By the end, we should be on the value.
            return node;
        };

        return <Tabs>
            { props.tabs.map( ( tab: TabCardType ) => <TabsContent key={tab.name} name={tab.name}>
                { tab.cards.map( ( cards: Card[] ) => {
                return <Grid key={ cards[0].name } container spacing={3} justifyContent="center" className="card-row">
                    { cards.map( ( card: any ) => {
                        let displayOptions: any = {};

                        let fields = [ 'compareInverted', 'precision', 'type' ];

                        for ( let field of fields ) {
                            if ( card[ field ] ) {
                                displayOptions[ field ] = card[ field ];
                            }
                        }

                        return <Grid key={card.name} item xs={3}>
                            <CompareCard
                                text={card.name}
                                values={[
                                    getPath( data, card.field ),
                                    getPath( data?.compare, card.field )
                                ]}
                                { ...{...cardOptions, ...displayOptions } }
                                // tooltip={getTooltip( ...fields[ "ROAS (CD)" ] )}
                            />
                        </Grid>
                        }
                    ) }
                </Grid> }) }
            </TabsContent>) }
        </Tabs>;
    }

    return <FilteredDatedContent
        title={props.title}
        api={props.api}
        params={props.params}
        dimensionParams={props.dimensionParams}
        
        enableCompare={props.enableCompare}
        enableSave={props.enableSave}

        render={render}

        context={props.context}
    />
}

export { TabCards };
