import { configureStore, createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TypedUseSelectorHook } from "react-redux";
import { accountsReducer } from "./AccountsSlice";
import { filtersReducer } from "./FiltersSlice";

// All

export const store = configureStore({
    reducer: {
        accounts: accountsReducer,
        filters: filtersReducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Export typed hooks so rest of app doesn't need to pass state.
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
