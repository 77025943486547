import { TabCards } from '../../../../components/ui/TabCards';
import { useFilters } from '../../../../hooks/useFilters';
import { useFields } from '../../../../hooks/useFields';

export function KissFacebookConversionSummary() {
    const { getFilter } = useFilters();
    const { getFieldsFromTabCards, getLayout } = useFields();

    const publicTabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend', 'impressions', 'cpm' ],
                [ 'ctr', 'cpc', 'link_ctr', 'cost_per_link_click' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ],
                [ 'aov', 'revenue', 'roas_float' ]
            ] )
        }
    ];

    const allTabs: any = publicTabs.concat([
        {
            name: 'Actions',
            cards: getLayout( [
                [ 'spend', 'impressions' ],
                [ 'clicks', 'link_clicks' ],
                [ 'add_to_carts', 'initiate_checkouts', 'purchases' ],
                [ 'aov', 'revenue', 'roas_float' ]
            ] )
        },
        {
            name: 'Rates',
            cards: getLayout( [
                [ 'spend' ],
                [ 'ctr', 'link_ctr' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ]
            ] )
        },
        {
            name: 'Costs',
            cards: getLayout( [
                [ 'spend', 'cpm' ],
                [ 'cpc', 'cost_per_link_click' ],
                [ 'cost_per_add_to_cart', 'cost_per_initiate_checkout', 'cost_per_purchase' ]
            ] )
        },
        {
            name: 'ROAS',
            cards: getLayout( [
                [ 'roas' ],
                [ 'click_dropoff', 'roas_cd', 'click_dropoff_spend' ],
                [ 'cpm', 'roas_cpm' ]
            ] )
        },
        {
            name: 'Goals',
            cards: getLayout( [
                [ 'clicks',  'ctr' ],
                [ 'link_clicks', 'link_ctr' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ],
                [ 'add_to_carts', 'initiate_checkouts', 'purchases' ]
            ] )
        },
        {
            name: 'Dropoffs',
            cards: getLayout( [
                [ 'landing_page_views', 'purchases' ],
                [ 'link_clicks', 'click_dropoff', 'click_dropoff_spend' ],
                [ 'add_to_carts', 'cart_dropoff', 'cart_dropoff_value' ],
                [ 'initiate_checkouts', 'checkout_dropoff', 'checkout_dropoff_value' ],
            ] )
        },
        {
            name: 'Funnel',
            cards: getLayout( [
                [ 'spend' ],
                [ 'impressions', 'reach' ],
                [ 'link_clicks', 'cost_per_link_click' ],
                [ 'landing_page_views', 'cost_per_landing_page_view' ],
                [ 'view_contents', 'cost_per_view_content', 'view_contents_value' ],
                [ 'add_to_carts', 'cost_per_add_to_cart', 'add_to_carts_value' ],
                [ 'initiate_checkouts', 'cost_per_initiate_checkout', 'initiate_checkouts_value' ],
                [ 'purchases', 'cost_per_purchase', 'revenue' ]
            ] )
        }
    ]);

    if ( getFilter( 'view' ) !== 'user' ) {
        return <TabCards
            title='Facebook Conversion'
            api='facebook/summary/ecom'
            params={{ fields: getFieldsFromTabCards(publicTabs).join( ',' ) }}
            dimensionParams={{ platform: 'facebook', type: 'ecom' }}
            tabs={publicTabs}
            enableCompare={true}
            enableSave={true}
        />
    }

    return <TabCards
        title='Facebook Conversion'
        api='facebook/summary/ecom'
        params={{ fields: getFieldsFromTabCards(allTabs).join( ',' ) }}
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        tabs={allTabs}
        enableCompare={true}
        enableSave={true}
    />
}
