import { KissFacebookAwarenessAds } from './facebook/awareness/Ads';
import { KissFacebookAwarenessAudiences } from './facebook/awareness/Audiences';
import { KissFacebookAwarenessCampaigns } from './facebook/awareness/Campaigns';
import { KissFacebookAwarenessCreatives } from './facebook/awareness/Creatives';
import { KissFacebookAwarenessSummary } from './facebook/awareness/Summary';

// import { FacebookBudgets } from './facebook/Budgets';
// import { FacebookComments } from './facebook/Comments';

import { KissFacebookConversionAds } from './facebook/ecom/Ads';
import { KissFacebookConversionAudiences } from './facebook/ecom/Audiences';
import { KissFacebookConversionCampaigns } from './facebook/ecom/Campaigns';
import { KissFacebookConversionCreatives } from './facebook/ecom/Creatives';
import { KissFacebookConversionSummary } from './facebook/ecom/Summary';

import { KissFacebookRetailerAds } from './facebook/retailer/Ads';
import { KissFacebookRetailerAudiences } from './facebook/retailer/Audiences';
import { KissFacebookRetailerCampaigns } from './facebook/retailer/Campaigns';
import { KissFacebookRetailerCreatives } from './facebook/retailer/Creatives';
import { KissFacebookRetailerSummary } from './facebook/retailer/Summary';

import { SharedFacebookMediaPlan } from '../shared/facebook/MediaPlan';
import { SharedFacebookPacing } from '../shared/facebook/Pacing';

import { KissReportingNails } from './reporting/Nails';

import { defaultRoutes } from '../shared';
import { Route } from '../../hooks/useRouter';

const customRoutes: Route[] = [
    {
        path: 'facebook/ads/awareness',
        element: KissFacebookAwarenessAds,
    },
    {
        path: 'facebook/audiences/awareness',
        element: KissFacebookAwarenessAudiences,
    },
    {
        path: 'facebook/campaigns/awareness',
        element: KissFacebookAwarenessCampaigns,
    },
    {
        path: 'facebook/creatives/awareness',
        element: KissFacebookAwarenessCreatives,
    },
    {
        path: 'facebook/summary/awareness',
        element: KissFacebookAwarenessSummary,
    },
    
    {
        path: 'facebook/ads/ecom',
        element: KissFacebookConversionAds,
    },
    {
        path: 'facebook/audiences/ecom',
        element: KissFacebookConversionAudiences,
    },
    {
        path: 'facebook/campaigns/ecom',
        element: KissFacebookConversionCampaigns,
    },
    {
        path: 'facebook/creatives/ecom',
        element: KissFacebookConversionCreatives,
    },
    {
        path: 'facebook/summary/ecom',
        element: KissFacebookConversionSummary,
    },
    
    {
        path: 'facebook/ads/retailer',
        element: KissFacebookRetailerAds,
    },
    {
        path: 'facebook/audiences/retailer',
        element: KissFacebookRetailerAudiences,
    },
    {
        path: 'facebook/campaigns/retailer',
        element: KissFacebookRetailerCampaigns,
    },
    {
        path: 'facebook/creatives/retailer',
        element: KissFacebookRetailerCreatives,
    },
    {
        path: 'facebook/summary/retailer',
        element: KissFacebookRetailerSummary,
    },

    {
        path: 'facebook/media-plan',
        element: SharedFacebookMediaPlan,
    },
    {
        path: 'facebook/pacing',
        element: SharedFacebookPacing,
    },

    {
        path: 'reporting/nails',
        element: KissReportingNails,
    }
];

export const routes = ( [] as Route[] ).concat(
    defaultRoutes.facebook,
    defaultRoutes.google,
    defaultRoutes.tiktok,
    customRoutes
);
