import { useFields } from '../../../hooks/useFields';
import { ColumnSelectTable } from '../../../components/ui/ColumnSelectTable';

export function SharedFacebookAds() {
    const { getFields, removeFields } = useFields();

    const fieldSets = removeFields(
        [ /^(adgroup|campaign)?_status/ ],
        getFields( [
            'fb_setup_ad',
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3',
            'fb_rename'
        ] )
    );

    const set = 'fb_ecom';

    return <ColumnSelectTable
        name='Facebook Ads'
        api='facebook/ads'
        dimensionParams={{ platform: 'facebook' }}
        columnSets={fieldSets}
        columnSet={set}
        enableCompare={true}
    />
}
