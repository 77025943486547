// may mess up numbers with quotes
const escape = ( text: string ): string => '"' + text.replace( /"/, '\\"' ) + '"'

const exportCsv = ( name: string, rows: any[][] ) => {
    // const delimiter = "\t";
    const delimiter = "\t";

    // Prepare data.

    // const data = rows.map( row => row.join( delimiter ) ).join( "\n" );
    const data = rows.map( row => row.join( delimiter ) ).join( "\n" );

    // Download file.

    // Create a link to click and initiate download.
    const a = document.createElement( 'a' );

    a.href = URL.createObjectURL( new Blob( [data], { type: 'text/csv' } ) );
    a.setAttribute( 'download', name + '.tsv' );
    document.body.appendChild( a );
    a.click();

    // Remove it after we're done.
    document.body.removeChild( a );
}

export function useExport() {
    return { exportCsv };
}
