import { TabCards } from '../../../components/ui/TabCards';
import { useFields } from '../../../hooks/useFields';

export function TikTokSummary() {
    const { getFieldsFromTabCards, getLayout } = useFields();

    const tabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend', 'impressions', 'cpm' ],
                [ 'ctr' ],
                [ 'purchases', 'purchase_rate', 'aov' ],
                // [ 'revenue', 'roas_float' ]
                [ 'revenue', 'roas' ]
            ] )
        }
    ];

    return <TabCards
        title='Tiktok Summary'
        api='tiktok/summary'
        params={{ fields: getFieldsFromTabCards(tabs).join( ',' ) }}
        dimensionParams={{ platform: 'tiktok' }}
        tabs={tabs}
        enableCompare={true}
        enableSave={true}
    />
}
