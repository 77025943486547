// import { Box, Button, Grid, Paper } from '@material-ui/core';
// import React, { Fragment, useState } from 'react';

// import { useAuth } from '../../../hooks/useAuth';
// import { useFilters } from '../../../hooks/useFilters';
// import { useFormat } from '../../../hooks/useFormat';

// import { FilteredDatedContent } from '../../../components/ui/FilteredDatedContent';
import { useFields } from '../../../hooks/useFields';
import { ChannelCreatives } from '../../../components/channels/Creatives';

export function TikTokCreatives() {
    const { getFields, removeFields } = useFields();

    const sets = removeFields(
        [
            /_name$/
        ],
        getFields( [ 'tt_ecom' ] )
    );

    const set = 'tt_ecom';

    return <ChannelCreatives
        title='TikTok Creatives'
        api='tiktok/creatives'
        dimensionParams={{ platform: 'tiktok' }}
        columnSets={sets}
        columnSet={set}
        defaultSort='Spend'
    />

    // const { canView } = useAuth();
    // const { filters } = useFilters();
    // const { formatMoney, formatNumber, formatPercent } = useFormat();

    // const [ numShown, setNumShown ] = useState( 6 );

    // const loadMore = () => {
    //     setNumShown( numShown + 6 );
    // }

    // let sort = canView( getFilter( 'view' ), 'user' )
    //     ? [ 'Spend', 'CTR', 'CVR', 'Impressions', 'Link CTR', 'Revenue', 'ROAS' ]
    //     : [ 'CTR', 'CVR', 'Impressions', 'Link CTR', 'Revenue', 'ROAS' ]
    // ;

    // const extraFilters = [
    //     {
    //         name: 'Sort',
    //         code: 'sort',
    //         list: sort,
    //         default: 'ROAS',
    //         all: false,
    //     }
    // ];

    // const render = ( data: any ) => {
    //     return <Paper className="content page-creatives">
    //         <Grid container>
    //             { data?.length ?
    //                 data.map(
    //                     ( ad: any, i: number ) => 
    //                         // Show content if the current position is less than
    //                         // the number we are showing.
    //                         i < numShown ? 
    //                         <Grid key={i} item xs={4} className="ad-container">
    //                             <Box display="flex" justifyContent="center">
    //                                 {/* Set the height in frontend so we have some flexibility. */}
    //                                 <div className="ad">
    //                                     <div className="info no-preview">                                            
    //                                         <Grid container>
    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ? <Fragment>
    //                                                     <Grid item xs={12} className="name center">{ad.campaign_name}</Grid>
    //                                                     <Grid item xs={12} className="name center">{ad.adset_name}</Grid>
    //                                                 </Fragment>
    //                                                 : ''
    //                                             }

    //                                             <Grid item xs={12} className="name center">{ad.name}</Grid>

    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ? <Fragment>
    //                                                     <Grid item xs={12} className="name center">{ad.id}</Grid>
    //                                                 </Fragment>
    //                                                 : ''
    //                                             }

    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ?   <Fragment>
    //                                                         <Grid item xs={8} className="stat">Spend</Grid>
    //                                                         <Grid item xs={4} className="value">{formatMoney( ad.spend )}</Grid>
    //                                                     </Fragment>
    //                                                 : ''
    //                                             }

    //                                             <Grid item xs={8} className="stat">Impressions</Grid>
    //                                             <Grid item xs={4} className="value">{formatNumber( ad.impressions )}</Grid>

    //                                             <Grid item xs={8} className="stat">CTR</Grid>
    //                                             <Grid item xs={4} className="value">{formatPercent( ad.ctr, 2 )}</Grid>

    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ?   <Fragment>
    //                                                         <Grid item xs={8} className="stat">Purchases</Grid>
    //                                                         <Grid item xs={4} className="value">{formatNumber( ad.purchases )}</Grid>
    //                                                     </Fragment>
    //                                                 : ''
    //                                             }

    //                                             <Grid item xs={8} className="stat">CVR</Grid>
    //                                             <Grid item xs={4} className="value">{formatPercent( ad.cvr, 2 )}</Grid>

    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ?   <Fragment>
    //                                                         <Grid item xs={8} className="stat">AOV</Grid>
    //                                                         <Grid item xs={4} className="value">{formatMoney( ad.aov )}</Grid>
    //                                                     </Fragment>
    //                                                 : ''
    //                                             }

    //                                             { canView( getFilter( 'view' ), 'user' ) 
    //                                                 ?   <Fragment>
    //                                                         <Grid item xs={8} className="stat">Revenue</Grid>
    //                                                         <Grid item xs={4} className="value">{formatMoney( ad.revenue )}</Grid>
    //                                                     </Fragment>
    //                                                 : ''
    //                                             }

    //                                             <Grid item xs={8} className="stat">ROAS</Grid>
    //                                             <Grid item xs={4} className="value">{formatPercent( ad.roas, 2 )}</Grid>

    //                                             { ad.type_dca || ad.type_catalog
    //                                                 ? <Fragment>
    //                                                     <Grid item xs={8} className="stat">Type</Grid>
    //                                                     <Grid item xs={4} className="value">
    //                                                         <a href={ad.link} target="_blank" rel="noreferrer">
    //                                                         { ad.type_dca ? 'Dynamic' : '' }
    //                                                         { ad.type_catalog ? 'Catalog' : '' }
    //                                                         </a>
    //                                                     </Grid>
    //                                                 </Fragment>
    //                                                 : <Grid item xs={12}>&nbsp;</Grid>
    //                                             }
    //                                         </Grid>
    //                                     </div>
    //                                 </div>
    //                             </Box>
    //                         </Grid>
    //                         : ''
    //                 )
    //                 : <div className="message">No ads found.</div>
    //             }
    //         </Grid>

    //         { numShown < data?.length ?
    //             <Box display="flex" justifyContent="center">
    //                 <Button onClick={loadMore}>Load More</Button>
    //             </Box>
    //             : ''
    //         }
    //     </Paper>
    // }

    // return <FilteredDatedContent
    //     title="Creatives"
    //     api='tiktok/creatives'
    //     filters={extraFilters}
    //     render={render}
    // />
}
