// import { resolve } from "dns";

import { useApi } from "../../../hooks/useApi";
import { useQuery } from "../../../hooks/useQuery";

import { Column, Table } from "../../ui/Table";
import { LoadedContent } from "../../ui/LoadedContent";

export function UsersList() {
    const { sendPost } = useApi();
    const { data, refresh, error, isLoaded } = useQuery( 'admin/users' );

    const capitalize = ( text: string ): string => text
        .split( /_/ )
        .map( ( item: string ) => item.charAt(0).toUpperCase() + item.slice(1) )
        .join( ' ' );

    const fields = [
        'id', 'group', 'enabled',
        'username', 'email', 'name',
        'level', 'permissions',
        'last_login', 'added'
    ];

    const renders: any = {
        enabled: function() {
            console.log(arguments);
            return 'dog';
        }
    }

    let columns: Column[] = fields.map( (field: string) => ({
        name: field === 'id' ? 'ID' : capitalize( field ),
        field: field,
        // render: renders[ field ] ?? null 
    }));

    return <LoadedContent loaded={isLoaded} error={error}>
        <h1>Users</h1>

        <Table
            columns={columns}
            data={ data || [] }
            rows={20}
            enableGroups={true}
            enableToolbar={true}

            actions={[
                {
                    icon: 'lock_reset',
                    tooltip: 'Reset',
                    onClick: ( event: any, row: any ) => {
                        sendPost( '/admin/users/reset', { id: row.id } )
                        .then( ( response: any ) => {
                            alert( response.message );
                        } )
                        .catch( err => {
                            alert( `ERROR: Unable to reset ${row.name}.\n\n` + err.message );
                        } );
                    }
                },
                {
                    icon: 'mail',
                    tooltip: 'Invite',
                    onClick: ( event: any, row: any ) => {
                        sendPost( '/admin/users/invite', { id: row.id } )
                        .then( ( response: any ) => {
                            alert( response.message );
                        } )
                        .catch( err => {
                            alert( `ERROR: Unable to invite ${row.name}.\n\n` + err.message );
                        } );
                    }
                },
                {
                    icon: 'delete',
                    tooltip: 'Delete',
                    onClick: ( event: any, row: any ) => {
                        sendPost( '/admin/users/delete', { id: row.id } )
                        .then( ( response: any ) => {
                            alert( response.message );
                        } )
                        .catch( err => {
                            alert( `ERROR: Unable to delete ${row.name}.\n\n` + err.message );
                        } );
                    }
                }
            ]}
            actionsColumn={1}
            edit={{
                columnsDisabled: [ 'id', 'last_login', 'added' ],
                onAdd: ( newRow: any ) => new Promise(
                    ( resolve, reject ) => {
                        console.log( 'add', newRow );
                    }
                ),
                // change to onEdit
                onUpdate: ( newRow: any, oldRow: any, diff: any ) => {
                    const url = '/admin/users/update';

                    const params = {
                        id: newRow.id,
                        // name: newRow.username,
                        changes: diff
                    };

                    const after = ( response: any ) => {
                        refresh();
                    }

                    return {
                        url, params, after
                    }
                }

                // onUpdate: ( newRow: any, oldRow: any, diff: any ) => new Promise(
                //     ( resolve, reject ) => {
                //         console.log( 'update', newRow, oldRow, diff );

                //         const params = {
                //             id: newRow.id,
                //             name: newRow.username,
                //             changes: diff
                //         };

                //         const response = sendPost( '/admin/users/update', params ).then(function(){
                //             console.log('then', arguments);
                //         }
                //         ).catch(err => console.log('err', err));

                //         console.log( 'response', response );

                //         resolve(true);
                //     }


                // )
                // (resolve: any, reject: any) => new Promise(
                //     (resovle, reject) => {
                //         // resolve( 'yo' );
                //         console.log('promised');
                //     }
                // )
            }}
        />
    </LoadedContent>
}
