import { useQuery } from "../../../hooks/useQuery";
import { LoadedContent } from "../../ui/LoadedContent";
import { Tabs, TabsContent } from "../../ui/Tabs";
import { Column, Table } from "../../ui/Table";
import { useAuth } from "../../../hooks/useAuth";
import { useFilters } from "../../../hooks/useFilters";

export function SystemStatus() {
    const { canView } = useAuth();
    const { getFilter } = useFilters();
    const { data, error, isLoaded } = useQuery( 'admin/system/cache/status' );

    let columns: Column[] = [
        {
            name: 'Client',
            field: 'client'
        },
        {
            name: 'Ad Account',
            field: 'account'
        },
        {
            name: 'Active',
            field: 'active'
        },
        {
            name: 'Latest',
            field: 'max',
            type: 'date'
        },
        {
            name: 'Oldest',
            field: 'min',
            type: 'date'
        }
    ]

    let displayColumns = columns;
    let displayData: any[] = data?.facebook;
    
    if ( !canView( getFilter( 'view' ), 'admin' ) ) {
        // Filter out inactive accounts for non-admins. Hide
        displayData = data?.facebook?.filter( ( row: any ) => row.active === 'Yes' );

        // Hide active column too.
        displayColumns = columns.filter( ( column: Column ) => column.field !== 'active' && column.field !== 'client' ); 
    }

    return <LoadedContent loaded={isLoaded} error={error}>
        <h1>System Status</h1>
        <Tabs>
            <TabsContent name="Facebook">
                <Table
                    columns={displayColumns}
                    data={displayData}
                />
            </TabsContent>
            {/* <TabsContent name="Queue">
                <h2>Pending</h2>
                <Grid container xs={6}>
                    { data?.queue?.pending?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Completed Today</h2>
                <Grid container xs={6}>
                    { data?.queue?.today?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Completed This Week</h2>
                <Grid container xs={6}>
                    { data?.queue?.week?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>
            </TabsContent>
            <TabsContent name="Facebook">
                <h2>Updated</h2>
                <Grid container xs={6}>
                    { data?.facebook?.updated?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.updated}</Grid>
                        </Fragment>
                    ) }
                </Grid>

                <h2>Queue</h2>
                <Grid container xs={6}>
                    { data?.facebook?.queue?.map( (item: any) =>
                        <Fragment>
                            <Grid item xs={6}>{item.name}</Grid>
                            <Grid item xs={6}>{item.count}</Grid>
                        </Fragment>
                    ) }
                </Grid>
            </TabsContent> */}
        </Tabs>
    </LoadedContent>
}
