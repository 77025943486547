import { matchRoutes, useLocation, useNavigate, useParams, useRoutes } from "react-router-dom"

import { SharedFacebookSummary } from "../../clients/shared/facebook/Summary";
import { SharedFacebookCampaigns } from '../../clients/shared/facebook/Campaigns';
import { SharedFacebookAudiences } from '../../clients/shared/facebook/Audiences';
import { Link } from "react-router-dom";
import { Select } from "../ui/Select";
import { useFilters } from "../../hooks/useFilters2";
import { useEffect, useMemo } from "react";

export function ReportsView() {
    const navigate = useNavigate();
    const params = useParams();

    let importedRoutes = [
        {
            path: 'facebook/summary',
            element: SharedFacebookSummary
        },
        {
            path: 'facebook/campaigns',
            element: SharedFacebookCampaigns
        },
        {
            path: 'facebook/audiences',
            element: SharedFacebookAudiences
        }
    ];

    let report = {
        name: 'KEC Status',
        dates: [ '22-05-29', '22-06-11' ],
        pages: [
            {
                name: 'KISS Memorial Day',
                url: 'facebook/summary',
                // account: 'kiss',
                // Internally these will be IDs when UI is setup.
                account: 1,
                data: {
                    date_start: '2022-05-01',
                    date_end: '2022-05-31',
                    area: 'Memorial Day'
                }
            },
            {
                name: 'imPRESS Color Campaigns',
                url: 'facebook/campaigns',
                account: 4,
                data: {
                    // date_start: '2022-06-01',
                    // date_end: '2022-06-12',
                    area: 'Color'
                }
            },
            {
                name: 'Falscara Acq Audiences',
                url: 'facebook/audiences',
                account: 11,
                data: {
                    date_start: '2022-06-01',
                    date_end: '2022-06-12',
                    funnel: 'Acquisition'
                }
            }
        ]
    };

    let currentRoutes: any = [];

    for ( let i in report.pages ) {
        for ( let route of importedRoutes ) {
            if ( route.path === report.pages[i].url ) {
                // let Component = route.element;
                currentRoutes.push({
                    path: params.id + '/' + ( parseInt(i) + 1 ),
                    name: report.pages[i].name,
                    account: report.pages[i].account,
                    data: report.pages[i].data,
                    element: route.element
                    // element: <Component />
                });

                break;
            }
        }
    }

    currentRoutes = currentRoutes.slice(0,1);
    // currentRoutes = Array(10).fill( currentRoutes[0] , 0, 10 );
console.log( 'currentRoutes', currentRoutes );
    // let routes = {
    //     'a': [
    //         {
    //             path: '1/:b',
    //             element: <p>ax1</p>
    //         },
    //         {
    //             path: '2',
    //             element: <p>ax2</p>
    //         },
    //         {
    //             path: '3',
    //             element: <p>ax3</p>
    //         },
    //         {
    //             path: '4',
    //             element: <p>ax4</p>
    //         }
    //     ],
    //     'b': [
    //         {
    //             path: '2/1',
    //             element: <p>b1</p>
    //         },    
    //         {
    //             path: '2/2',
    //             element: <p>b2</p>
    //         },    
    //         {
    //             path: '2/3',
    //             element: <p>b3</p>
    //         },    
    //     ]
    // }

    const list = [
        {
            path: 'reports/*',
            element: <h1>BAD</h1>,
            children: [
                ...currentRoutes
                // ...routes.a,
                // ...routes.b        
            ]
        }
    ];

    const location = useLocation();
    const matches: any = matchRoutes( list, location );

    const element = useRoutes(
        list, location
    );

    const matchedRoute = matches ? matches[ matches.length - 1 ] : null;
    const Component = matchedRoute?.route?.element;

    const currentPage = parseInt( params?.page ?? '1' );
    const prevPage = currentPage ? Math.max( currentPage - 1, 1 ) : 1;
    const nextPage = currentPage ? Math.min( currentPage + 1, report.pages.length ) : 1;

    const { setFiltersAtPrefix } = useFilters( null, false );

    useEffect( () => {
console.log( 'View set report data');
        setFiltersAtPrefix( 'report-0',  currentRoutes[0]?.data ?? {} );
        // setFiltersAtPrefix( 'report-1', currentRoutes[1].data);
        // setFiltersAtPrefix( 'report-2', currentRoutes[2].data);   
    }, [ currentRoutes ]);

    let context: any = [];

    for ( let i in currentRoutes ) {
        context[i] = {
            account: currentRoutes[i].account,
            filterPrefix: 'report-' + i,
            filters: currentRoutes[i].data,
            global: false
        };
    }
console.log( 'contextx', context );
    let a = 1;
    const contextMemo = useMemo( () => {
        console.log( 'memo? ', currentRoutes );
        return context;
    }, [ a ] );

    // const context = useMemo({
    //     ...{ filters: route.data },
    //     ...{ filterPrefix: 'report-' + i }
    // });


    // console.log('routes', currentRoutes);

    return <div>
        <h1>{report.name}</h1>

        <Select
            id="page"
            value={currentPage}
            items={ currentRoutes.map( ( route: any, i: any ) => {
                return {
                    text: (i + 1) + ': ' + route.name,
                    value: (i + 1).toString()
                }
            } ) }
            onChange={ (id: string, value: string) => navigate(`/reports/${params.id}/${value}`) }
        />

        <p>
            { prevPage < currentPage ? <Link to={`/reports/${params.id}/${prevPage}`}>{prevPage}</Link> : '' }
            &nbsp;
            { nextPage > currentPage ? <Link to={`/reports/${params.id}/${nextPage}`}>{nextPage}</Link> : '' }
        </p>
        
        {
            // currentRoutes.map(
            currentRoutes.map(
                ( route: any, i : any) => {
                    const Component = route.element;
                    const isActive = route.path === matchedRoute.route.path;
                    return <div key={i} style={{ display: isActive ? 'block' : 'block', border: isActive ? '5px solid red' : '' }}>
                        <Component context={contextMemo[i]} />
                    </div>
                }
            )
        }
        {/* { matchedRoute ? <Component init={matchedRoute.route.data} />  : 'no manches' } */}
    </div>
}
