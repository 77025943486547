import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objectContains } from "../lib/Tools";

export interface FilterMultiValue {
    [ key: string ]: any;
}

export interface FilterPrefixValue {
    prefix?: string;
    data: FilterMultiValue;
}

export interface FilterSingleValue {
    key: string;
    value: any;
}

export interface FiltersState {
    [ prefix: string ]: FilterMultiValue;
}

const initialState: FiltersState = {};

const filtersSlice = createSlice({
    name: 'filters',
    initialState: initialState,
    reducers: {
        // Fully replaces all data with new filter.
        setAllFilters: ( state: FiltersState, action: PayloadAction<FilterPrefixValue> ) => {
console.log( 'filterslice setAllFilters', action, state, this );
            // state = action.payload;
            // return action.payload;
            // return;

            const prefix = action.payload.prefix ?? '';

            const data = action.payload.data;

            // If payload has no new data, don't do anything.
            if ( objectContains( state[ prefix ], data ) ) {
console.log( 'slice setAllFilters skip', action.payload.data, 'is in', state[ prefix ] )
                return;
            }

            // Overwrite data at prefix.
            const newState: FiltersState = {
                ...state,
                ...{ [ prefix ]: data }
            };

console.log('filterslice newstate', action.payload.prefix, action.payload.data, "\n", state, "=>", newState);

            return newState;

        },
//         setFilter: ( state: FiltersState, action: PayloadAction<FilterPrefixValue> ) => {
// console.log( 'filterslice setFilter', action, state );

//             const prefix = action.payload.prefix ?? '';

//             const data = action.payload;
//             // const newData = { [ action.payload.key ]: action.payload.value };

//             // state = {
//             //     ...state,
//             //     ...{ [action.payload.key]: action.payload.value }
//             // };
//         },
        // Adds new filters. Does not fully replace.
        setFilters: ( state: FiltersState, action: PayloadAction<FilterPrefixValue> ) => {
console.log( 'filterslice setFilters', action, state );

            const prefix = action.payload.prefix ?? '';

            // If payload has no new data, don't do anything.
            if ( objectContains( state[ prefix ], action.payload.data ) ) {
console.log( 'slice setAllFilters skip', action.payload.data, 'is in', state[ prefix ] )
                return;
            }

            // Add to data at prefix.
            const newState: FiltersState = {
                ...state,
                ...{
                    [ prefix ]: {
                        ...( state[ prefix ] ?? {} ),
                        ...action.payload.data
                    }
                }
            };

            return newState;
        },
//         unsetFilter: ( state: FiltersState, action: PayloadAction<string> ) => {
// console.log( 'filterslice unsetFilter', action, state );
//             delete state[ action.payload ];
//         },
        unsetFilters: ( state: FiltersState, action: PayloadAction<string[]> ) => {
console.log( 'filterslice unsetFilters', action, state );
            for ( let key of action.payload ) {
                delete state[ key ];
            }

            return state;
        },
    }
});

export const filtersReducer = filtersSlice.reducer;
export const { setAllFilters, setFilters, unsetFilters } = filtersSlice.actions;
