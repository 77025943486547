import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createStore } from 'reusable';

import { useAuth } from './useAuth';
import { useDate } from './useDate';
import { useRouter } from './useRouter';
// import { useSession } from './useSession';

interface Object {
    [ key: string ]: any;
}

const isEqual = ( a: Object | undefined, b:Object | undefined ): boolean => {
    for ( let key of Object.keys( a ?? {} ) ) {
        if ( b?.[ key ] !== a?.[ key ] ) {
            return false;
        }
    }

    for ( let key of Object.keys( b ?? {} ) ) {
        if ( a?.[ key ] !== b?.[ key ] ) {
            return false;
        }
    }

    return true;
}

const keyExists = ( object: any, key: string ): boolean => {
    // We can't check if just the value is undefined because a key can have an undefined value. We need to make sure the key doesn't exist.
    return Object.keys( object ).indexOf( key ) !== -1;
}

// Convert boolean text values to real booleans.
// TODO: To be removed.
const parseParams = ( params: Object ): Object => {
    let parsed: Object = {};

    for ( let [ key, value ] of Object.entries( params ) ) {
        if ( value === 'true' ) {
            value = true;
        } else if ( value === 'false' ) {
            value = false;
        }

        parsed[ key ] = value;
    }

    return parsed; 
}

export const useFilters = createStore( () => {
    const { params, setParam, setParams, unsetParam, unsetParams } = useRouter();
    const { getDate } = useDate();

    const { auth } = useAuth();

    // The default prefix is saved in search params.
    const defaultPrefix = 'global';

    const [ filters, setAllFilters ]: any = useState( { [defaultPrefix]: parseParams( params ) } );

    // Standard methods

    const clearFilters = ( prefix?: string ) => {
        // Avoid state update if data stays the same.
        // Two empty objects are not equal so we have to check the number of keys.
        if ( Object.keys( filters[ getPrefix( prefix ) ] ).length === 0 ) {
            return;
        }

        setFilters( {}, prefix );

        // TODO: Redundant because setfilters takes care of prams too
        // if ( prefix === defaultPrefix ) {
        //     setParams({});
        // }
    }

    const getFilter = ( key: string, prefix?: string ) => {
        const all = getFilters( prefix );

        return all?.[ key ];
        // return filters[ getPrefix( prefix ) ]?.[ key ];
    }

    const getFilters = ( prefix?: string ) => {
        return filters[ getPrefix( prefix ) ] ?? {};
    }

    // Merge into object instead of having a variable for each filter.
    const setFilter = ( key: string, value: any, prefix?: string ) => {
        setFilters( { [key]: value }, prefix );
    };

    const setFilters = ( data: Object, prefix?: string ) => {
        setAllFilters( ( previous: any ) => {
            const thisPrefix = getPrefix( prefix );

            // Avoid state update if data stays the same.

            // Look for values that match.
            let matchCount = 0;

            for ( let key of Object.keys( data ) ) {
                // Count matches.
                if ( previous[ thisPrefix ]?.[ key ] === data[ key ] ) {
                    matchCount++;
                }
            }

            // If all items match, we don't need to do anything.
            if ( matchCount === Object.keys( data ).length ) {
                return previous;
            }

            // Add new data to prefix.
            const filterData: any = {
                ...previous,
                ...{
                    [thisPrefix]: {
                        ...( previous[ thisPrefix ] ?? {} ),
                        ...data
                    }
                }
            };
console.log('set filters', prefix, data, filterData);
            if ( prefix === defaultPrefix ) {
                setParams( data );
            }

            return filterData;
        } );
    }

    const unsetFilter = ( key: string, prefix?: string ) => {
        unsetFilters( [ key ], prefix );
    };

    const unsetFilters = ( keys: string[], prefix?: string ) => {
        setAllFilters( ( previous: any ) => {
            const thisPrefix = getPrefix( prefix );

            // Avoid state update if data stays the same.

            // Look for missing keys.
            let matchCount = 0;

            for ( let key of keys ) {
                // Count missing keys.
                if ( !keyExists( previous[ thisPrefix ], key ) ) {
                    matchCount++;
                }
            }

            // If all keys are missing, we don't need to do anything.
            if ( matchCount === Object.keys( keys ).length ) {
                return previous;
            }

            for ( let key of keys ) {
                delete previous[ thisPrefix ][ key ];
            }

            if ( prefix === defaultPrefix ) {
                unsetParams( keys );
            }

            return previous;
        } );
    };

    // Prefix methods

    const getPrefix = ( text?: string ) => text ?? defaultPrefix;

    const getMethodsForIndex = ( prefix?: string ) => {
        return {
            clearFilters: () => clearFilters( prefix ),
            getFilter: ( key: string ) => getFilter( key, prefix ),
            getFilters: () => getFilters( prefix ),
            setFilter: ( key: string, value: any ) => setFilter( key, value, prefix ),
            setFilters: ( data: { [ key: string ]: any } ) => setFilters( data, prefix ),
            unsetFilter: ( key: string ) => unsetFilter( key, prefix ),
            unsetFilters: ( keys: string[] ) => unsetFilters( keys, prefix )
        }
    }

    // Create ref to track last value.
    const lastValue = useRef();

    useEffect( () => {
        // If default index has changed, set search params.
        if ( !isEqual( filters[ defaultPrefix ], lastValue.current?.[ defaultPrefix ] ) ) {
            setParams( filters[ defaultPrefix ] );
        }

        // Set last value to check for changes later.
        lastValue.current = filters;

        const now: number = Date.now();

        // Collect updates in array and update them all at once. This may no be necessary with React 18.
        let newData: { [ key: string ]: string } = {};

        if ( !filters[ defaultPrefix ].date_start ) {
            newData[ 'date_start' ] = getDate( 'Y-m-d', new Date( now - 30 * 86400 * 1000 ) );
        }

        if ( !filters[ defaultPrefix ].date_end ) {
            newData[ 'date_end' ] = getDate( 'Y-m-d', new Date( now - 86400 * 1000 ) );
        }

        if ( !filters[ defaultPrefix ].view ) {
            // setFilter( 'view', auth.level );
            newData[ 'view' ] = auth.level;
        }

        // TODO: WHY is this even there?
        // if ( auth.level !== filters.view ) {
        //     newData[ 'view' ] = auth.level;
        // }
        // setFilter( 'view', auth.level );

        // Set data at once to avoid multiple re-renders.
        if ( Object.keys( newData ).length ) {
            setFilters( newData, defaultPrefix );
        }
console.log( 'filters UE', filters );
    // }, [ auth.level, filters.date_start, filters.date_end, location ] );
    }, [ auth.level, filters ] );

    return {
        getMethodsForIndex,
        getFilter,
        getFilters,
        setFilter,
        setFilters,
        unsetFilter,
        unsetFilters,
        clearFilters
    };
} );
