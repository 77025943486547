import { ChannelCreatives } from '../../../components/channels/Creatives';
import { useFields } from '../../../hooks/useFields';

export function SharedFacebookCreatives() {
    const { getFields, removeFields } = useFields();

    const sets = removeFields(
        [
            /_name$/,
            'campaign_status_effective', 'adgroup_status_effective',
            'ad_thumbnail'
        ],
        getFields( [
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3'
            ,'fb_kiss_traffic', 'fb_kiss_sales'
        ] )
    );

    const set = 'fb_ecom';

    return <ChannelCreatives
        title='Facebook Creatives'
        api='facebook/creatives'
        dimensionParams={{ platform: 'facebook' }}
        columnSets={sets}
        columnSet={set}
        defaultSort='Revenue'
    />
}
