import MaterialTable from "@material-table/core";
import { Table } from "./ui/Table";

export function Test() {
    const data = [
        {
            name: 'Jane',
            age: 35,
            color: 'Green',
            score: 1000
        },
        {
            name: 'Jim',
            age: 47,
            color: 'Purple',
            score: 400
        }
    ];

    const columns = [
        {
            name: 'Name',
            field: 'name'
        },
        {
            name: 'Age',
            field: 'age'
        },
        {
            name: 'Color',
            field: 'color'
        },
        {
            name: 'Score',
            field: 'score'
        }
    ];

    return <>
        Testing

        <Table
            data={data}
            columns={columns}
            actions={[
                {
                    icon: 'lock_reset',
                    tooltip: 'Reset',
                },
                {
                    icon: 'mail',
                    tooltip: 'Invite',
                },
                {
                    icon: 'delete',
                    tooltip: 'Delete',
                }
            ]}
            edit={{
                onAdd: function(){
                    console.log('add')
                },
                onUpdate: function(){
                    console.log('update')
                }
            }}
            enableToolbar={true}
            enableDownload={true}
        />

        {/* <MaterialTable
            columns={[
                { field: 'type', title: 'Type' },
                { field: 'color', title: 'Color' }
            ]}
            data={[
                { type: 'Bird', color: 'Red' },
                { type: 'Snake', color: 'Green' },
            ]}
            options={{
                exportButton: true
            }}
        /> */}
    </>
}
