import { useFields } from '../../../hooks/useFields';
import { ColumnSelectTable } from '../../../components/ui/ColumnSelectTable';

export function ShopifyOrders() {
    const { getFields } = useFields();

    const fieldSets = getFields( [
        'shopify_order_basic',
        'shopify_order_full'
    ] )

    const set = 'shopify_order_basic';

    return <ColumnSelectTable
        name='Shopify Orders'
        api='shopify/orders'
        columnSets={fieldSets}
        columnSet={set}
        dimensionParams={{ platform: 'shopify' }}
        enableDownload={false}
    />
}
