import * as htmltoImage from 'html-to-image';

const save = ( blob: any, fileName: string ) =>{
    var element: any = window.document.createElement( 'a' );

    element.href = blob
    element.download = fileName;
    element.style = 'display:none;';

    (document.body || document.documentElement).appendChild( element );

    // Use element's simulated click ability.
    if ( typeof element.click === 'function' ) {
        element.click();
    } else {
        // If it doesn't have one, create a synthetic event.
        element.target = '_blank';
        element.dispatchEvent(
            new MouseEvent( 'click', {
                view: window,
                bubbles: true,
                cancelable: true
            })
        );
    }

    // Cleanup
    URL.revokeObjectURL( element.href );
    element.remove();
}

// Use a callback to get the name because no DOM elements exist before render fire so CSS selectors will fail.
const saveImage = ( selector: any, nameCallback: any ) => {
    const element = document.querySelector( selector );

    if ( !element ) {
        // console.error( selector, "not found")
        return;
    }

    htmltoImage.toPng( element )
    .then( ( url: string ) => {
        console.log(url);
        save( url, nameCallback() + '.png');
    })
}

export function useScreenshot() {
    return { saveImage };
}
