import { ChannelCreatives } from '../../../../components/channels/Creatives';
import { useFields } from '../../../../hooks/useFields';

export function KissFacebookConversionCreatives() {
    const { getFields, removeFields } = useFields();

    const sets = removeFields(
        [
            'campaign_name', 'adgroup_name', 'ad_name',
            'campaign_status_effective', 'adgroup_status_effective',
            'ad_thumbnail'
        ],
        getFields( [
            'fb_ecom', 'fb_ecom1', 'fb_ecom_funnel', 'fb_ecom_funnel2', 'fb_ecom_funnel3',
            'fb_kiss_sales', 'fb_kiss_traffic'
        ] )
    );

    const set = 'fb_ecom';

    return <ChannelCreatives
        title='Facebook Conversion Creatives'
        api='facebook/creatives/ecom'
        dimensionParams={{ platform: 'facebook', type: 'ecom' }}
        columnSets={sets}
        columnSet={set}
        defaultSort='Revenue'
    />
}
