import { useAuth } from '../../../hooks/useAuth';
import { useFields } from '../../../hooks/useFields';

import { TabCards } from '../../../components/ui/TabCards';
import { useEffect } from 'react';
import { ContextProps } from '../../../components/Types';
import { useDebugRenderCount } from '../../../hooks/useDebugRenderCount';
import { useFilters } from '../../../hooks/useFilters';
// import { useFilters } from '../../../hooks/useFilters2';

export function SharedFacebookSummary( props: ContextProps ) {
console.log( 'FB summary', props );
    const { canView } = useAuth();
    const { getFieldsFromTabCards, getLayout } = useFields();

    // Old
    const { getMethodsForIndex } = useFilters();
    const { getFilter } = getMethodsForIndex( props.context?.filterPrefix );

    // new
    // const { getFilter } = useFilters( props.context?.filterPrefix, props.context?.global );

const renderCount = useDebugRenderCount();
console.log( 'summary context hash', JSON.stringify( props.context ?? {} ).length );
console.log( 'summary renders', renderCount )

    const publicTabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'spend', 'impressions', 'cpm' ],
                [ 'ctr', 'cpc', 'link_ctr', 'cost_per_link_click' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ],
                [ 'aov', 'revenue', 'roas_float' ]
            ] )
        }
    ];

    const allTabs: any = publicTabs.concat([
        {
            name: 'Actions',
            cards: getLayout( [
                [ 'spend', 'impressions' ],
                [ 'clicks', 'link_clicks' ],
                [ 'add_to_carts', 'initiate_checkouts', 'purchases' ],
                [ 'aov', 'revenue', 'roas_float' ]
            ] )
        },
        {
            name: 'Rates',
            cards: getLayout( [
                [ 'spend' ],
                [ 'ctr', 'link_ctr' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ]
            ] )
        },
        {
            name: 'Costs',
            cards: getLayout( [
                [ 'spend', 'cpm' ],
                [ 'cpc', 'cost_per_link_click' ],
                [ 'cost_per_add_to_cart', 'cost_per_initiate_checkout', 'cost_per_purchase' ]
            ] )
        },
        {
            name: 'ROAS',
            cards: getLayout( [
                [ 'roas' ],
                [ 'click_dropoff', 'roas_cd', 'click_dropoff_spend' ],
                [ 'cpm', 'roas_cpm' ]
            ] )
        },
        {
            name: 'Goals',
            cards: getLayout( [
                [ 'clicks',  'ctr' ],
                [ 'link_clicks', 'link_ctr' ],
                [ 'add_to_cart_rate', 'initiate_checkout_rate', 'purchase_rate' ],
                [ 'add_to_carts', 'initiate_checkouts', 'purchases' ]
            ] )
        },
        {
            name: 'Dropoffs',
            cards: getLayout( [
                [ 'landing_page_views', 'purchases' ],
                [ 'link_clicks', 'click_dropoff', 'click_dropoff_spend' ],
                [ 'add_to_carts', 'cart_dropoff', 'cart_dropoff_value' ],
                [ 'initiate_checkouts', 'checkout_dropoff', 'checkout_dropoff_value' ],
            ] )
        },
        {
            name: 'Funnel',
            cards: getLayout( [
                [ 'spend' ],
                [ 'impressions', 'reach' ],
                [ 'link_clicks', 'cost_per_link_click' ],
                [ 'landing_page_views', 'cost_per_landing_page_view' ],
                [ 'view_contents', 'view_contents_value' ],
                [ 'add_to_carts', 'add_to_carts_value' ],
                [ 'initiate_checkouts', 'initiate_checkouts_value' ],
                [ 'purchases', 'revenue' ]
            ] )
        }
    ]);

    if ( !canView( getFilter( 'view' ), 'user' ) ) {
        return <TabCards
            title='Facebook'
            api='facebook/summary'
            params={{ fields: getFieldsFromTabCards(publicTabs).join( ',' ) }}
            dimensionParams={{ platform: 'facebook' }}
            enableCompare={true}
            tabs={publicTabs}
            context={props.context}
        />
    }

    return <TabCards
        title='Facebook Summary'
        api='facebook/summary'
        params={{ fields: getFieldsFromTabCards(allTabs).join( ',' ) }}
        dimensionParams={{ platform: 'facebook' }}
        tabs={allTabs}
        enableCompare={true}
        enableSave={true}
        context={props.context}
    />
}
