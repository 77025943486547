import { ChannelCreatives } from '../../../../components/channels/Creatives';
import { useFields } from '../../../../hooks/useFields';

export function KissFacebookAwarenessCreatives() {
    const { getFields, removeFields } = useFields();

    const sets = removeFields(
        [
            'campaign_name', 'adgroup_name', 'ad_name',
            'campaign_status_effective', 'adgroup_status_effective',
            'ad_thumbnail'
        ],
        getFields( [ 'fb_aware', 'fb_aware_full' ] )
    );

    const set = 'fb_aware';

    return <ChannelCreatives
        title='Facebook Awareness Creatives'
        api='facebook/creatives/awareness'
        dimensionParams={{ platform: 'facebook', type: 'awareness' }}
        columnSets={sets}
        columnSet={set}
        defaultSort='Impressions'
    />
}
