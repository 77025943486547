import { useState } from 'react';
import { createStore } from 'reusable';

import { useSession } from './useSession';

export const useAuth = createStore( () => {
    const { getSession, setSession, unsetSession } = useSession();

    const [ , setAuth ] = useState({});

    const sessionAuth = getSession( 'auth', true, true, {} );

    const hasAccess = ( fromLevel: string, toLevel: string ): boolean => {
        const levels = [ 'client_user', 'client_manager', 'client_admin', 'user', 'manager', 'admin' ];

        const fromIndex = levels.indexOf( fromLevel );
        const toIndex = levels.indexOf( toLevel );

        return fromIndex >= toIndex;
    }

    const hasPermission = ( permissions: any, account: string, permission: string ): boolean => {
        const requestedScope = `${account}.${permission}`;

        for ( let scope of permissions ) {
            // Convert 'code.*' to 'code\.\w+'
            // React complains of unnecessary escape character but we want to turn '.' to '\.' to use in a later regex.
            let regex = new RegExp( scope.replace( /\./g, '\.' ).replace( /\*/g, '\\w+' ) );

            if ( requestedScope.match ( regex ) ) {
                return true;
            }
        }

        return false;
    }

    // Session is expired if its past the expiration date or it has none.
    const currentTime = Math.floor( Date.now() / 1000 );
    const isExpired = !sessionAuth?.expires || currentTime > sessionAuth.expires;

    const isLoggedIn = Object.keys( sessionAuth ).length ? true : false ;

    const setCompleteAuth = ( user: object | null ) => {
        if ( !user ) {
            unsetSession( 'auth' );
            setAuth({});

            return;
        }

        // Can be null to reset.
        setSession( 'auth', user, true, true );

        if ( Object.keys( user ).length ) {
            setAuth( user );
        }
    }

    return {
        auth: sessionAuth,
        canView: ( from: string, to: string ): boolean => hasAccess( from, to ),
        hasAccess: ( level: string ): boolean => hasAccess( sessionAuth.level, level ),
        hasPermission: ( account: string, permission: string ): boolean => hasPermission( sessionAuth.permissions, account, permission ),
        isLoggedIn,
        isExpired,
        setAuth: setCompleteAuth
    };
} );
