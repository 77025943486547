import { Grid, Icon, IconButton, Paper, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';

import { useAccounts } from '../../hooks/useAccounts';
import { useAuth } from '../../hooks/useAuth';
import { DateRange, useDate } from '../../hooks/useDate';
// import { useFilters } from '../../hooks/useFilters2';
import { useFilters } from '../../hooks/useFilters';
import { usePage } from '../../hooks/usePage';
import { useQuery } from '../../hooks/useQuery';
import { useScreenshot } from '../../hooks/useScreenshot';
import { ContextProps } from '../Types';

import { DateRangePicker } from './DateRangePicker';
import { LoadedContent } from './LoadedContent';

interface Props extends ContextProps {
    title: string;
    api: string;

    params?: Object;

    enableCompare?: boolean;
    enableDates?: boolean;
    enableSave?: boolean;

    renderContent: (data: any, options: any) => JSX.Element;
    renderTopbar?: (data: any, options: any) => JSX.Element;

    topbarLoaded?: boolean;
    topbarError?: string|null;
    topbarRefresh?: any;

    children?: React.ReactNode;
}

export function DatedContent( props: Props ) {
    const { canView } = useAuth();
    const { addDays, getDate, getDifferenceInDays } = useDate();
    const { getDownloadName } = usePage();
    const { saveImage } = useScreenshot();

    const { account, setAccount } = useAccounts();

    // const { getMethodsForIndex: getMethodsForIndexAccounts } = useAccounts();
    // const { account, setAccount } = getMethodsForIndexAccounts( props.context?.filterPrefix );


// const { getFilter, setFilter, setFilters, unsetFilters } = getMethodsForIndex( props.context?.filterPrefix );
const { getMethodsForIndex: getMethodsForIndexFilters } = useFilters();
const { getFilter, setFilter, setFilters, unsetFilters, getFilters } = getMethodsForIndexFilters( props.context?.filterPrefix );

// const { getFilter, setFilter, setFilters, unsetFilters, getFilters } = useFilters( props.context?.filterPrefix );


console.log( "DatedContent context", props.context,
    "\nDatedContent props", props, "\nDatedContent filters", getFilters() )

    // if ( props.context?.filterPrefix ) {
    //     setAccount()
    // }

    // let params: any = props.params ?? {} ;
    let params: any = {
        ...( props.params ?? {} )
        // ...( props.context?.filters ?? {} )
    };

    const { data, refresh, error: queryError, isLoaded: queryLoaded } = useQuery( props.api, params, false, props.context );

    const reload = () =>  {
        refresh();

        if ( props.topbarRefresh ) {
            props.topbarRefresh();
        }
    }

    const setDateRange = ( range: DateRange ) => {
        setFilters({
            date_start: range.start,
            date_end: range.end
        });
    }

    const setCompareDateRange = ( range: DateRange ) => {
        setFilters({
            compare_date_start: range.start,
            compare_date_end: range.end
        });
    }

    const toggleCompareEnabled = () => {
        // const date = new DateFns();

        // If it's off, we're turning on. When turning on, dyamically set the previous period.
        if ( !getFilter( 'compare_enabled' ) ) {
            // Get current period length to mirror.
            const length = getDifferenceInDays( getFilter( 'date_start' ), getFilter( 'date_end' ) );
            
            const range: DateRange = {
                start: getDate( 'Y-m-d', addDays( getFilter( 'date_start' ), -1 * length - 1 ) ),
                end: getDate( 'Y-m-d', addDays( getFilter( 'date_start' ), -1 ) )
            };

            setCompareDateRange( range );
            // setFilters({
            //     compare_date_start: getDate( 'Y-m-d', start ),
            //     compare_date_end: getDate( 'Y-m-d', end ),
            // });
        }

        // If it's on, we're turning off. If we're turning off, remove other compare data so it doesn't get passed into backend.
        if ( getFilter( 'compare_enabled' )  && ( getFilter( 'compare_date_start' ) || getFilter( 'compare_date_end' ) ) ) {
            unsetFilters( [ 'compare_date_start', 'compare_date_end' ] );
        }

        setFilter( 'compare_enabled', !getFilter( 'compare_enabled' ) );
    }

    const toggleCompareShowValue = () => setFilter( 'compare_show_value', !getFilter( 'compare_show_value' ) );

    // Show compare when it's enabled and we have compare data. We have compare
    // data if the compare key exists on the response. This avoids flashing the
    // compare UI with empty data when the UI option is checked but no data is
    // loaded yet.

    let hasCompareData = data?.compare ? true : false ;

    const options = {
        compare: {
            enabled: getFilter( 'compare_enabled' ) && hasCompareData,
            keys: data?.compare?.keys,
            // Don't show value if compare is not enabled or we don't have data.
            showValue: getFilter( 'compare_enabled' ) && getFilter( 'compare_show_value' ) && hasCompareData
        }
    };

    // Default to show dates.
    const showDates = props.enableDates ?? true ;

    return <LoadedContent
        loaded={ queryLoaded && ( props.topbarLoaded ?? true ) }
        error={queryError || ( props.topbarError ?? '' ) }
    >
        <h1>{account?.name}</h1>

        <h2>{props.title}</h2>

        <Grid container className="filters" justifyContent="space-between">
            <Grid item className="dropdowns">
                { props.renderTopbar ? props.renderTopbar( data, options ) : '' }
            </Grid>
            <Grid item className="date-select">
                <Paper className="date-control">
                    { showDates
                        ? <>
                            <DateRangePicker start={ getFilter( 'date_start' ) } end={ getFilter( 'date_end' ) } onChange={setDateRange} />

                            { props.enableCompare
                                ? <IconButton className={ getFilter( 'compare_enabled' ) ? "toolbar enabled" : "toolbar" } onClick={toggleCompareEnabled}>
                                    <Tooltip title="Compare">
                                        <Icon>swap_horiz</Icon>
                                    </Tooltip>
                                </IconButton>
                                : ''
                            }
                        </>
                        : ''
                    }

                    { props.enableSave && canView( getFilter( 'view' ) , 'user' )
                        ? <IconButton className="toolbar" onClick={ () => saveImage('.tab-content .content', getDownloadName ) }>
                            <Icon>photo</Icon>
                        </IconButton>
                        : ''
                    }

                    <IconButton className="toolbar" onClick={reload}>
                        <Tooltip title="Refresh">
                            <Icon>refresh</Icon>
                        </Tooltip>
                    </IconButton>

                    { props.enableCompare && getFilter( 'compare_enabled' ) && getFilter( 'compare_date_start' )
                        ? <div className="compare">
                            <DateRangePicker start={getFilter( 'compare_date_start' )} end={getFilter( 'compare_date_end' )} onChange={setCompareDateRange} />
                            <IconButton className={ getFilter( 'compare_show_value' ) ? "toolbar enabled" : "toolbar" } onClick={toggleCompareShowValue}>
                                <Tooltip title="Show Previous">
                                    <Icon>looks_two</Icon>
                                </Tooltip>
                            </IconButton>
                        </div>
                        : ''
                    }
                </Paper>
            </Grid>
        </Grid>

        {props.children}

        { props.renderContent ? props.renderContent( data, options ) : '' }
    </LoadedContent>;
}
