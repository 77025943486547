import { TabCards } from "../../../components/ui/TabCards";
import { useFields } from "../../../hooks/useFields";

export function ShopifySummary() {
    const { getFieldsFromTabCards, getLayout } = useFields();

    const tabs: any = [
        {
            name: 'Overview',
            cards: getLayout( [
                [ 'orders' ],
                [ 'order_gross_amount', 'order_gross_aov' ],
                [ 'order_amount', 'order_aov' ],
                [ 'order_discount_amount', 'order_discount_average' ],
                [ 'order_return_amount', 'order_return_percent' ],
                //  'order_shipping_amount'
                // [ 'revenue', 'discounts', 'returns', 'net_revenue' ],
                // [ 'orders', 'aov', ],
            ] )
        },
        // Revenue breakdown gross, net ex
        // returns
    ];

    return <TabCards
        title='Shopify Summary'
        api='shopify/summary'
        params={{ fields: getFieldsFromTabCards(tabs).join( ',' ) }}
        dimensionParams={{ platform: 'shopify' }}
        tabs={tabs}
        enableCompare={true}
        enableSave={true}
    />
}
