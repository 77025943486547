import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Account {
    id: number;
    code: string;
    name: string;
    platforms: string[];
}

export interface AccountsState {
    accounts: Account[];
    selectedAccount?: number;
    loaded?: boolean;
    error?: string;
}

const initialState: AccountsState = {
    accounts: [],
    loaded: false,
    error: ''
};

const accountsSlice = createSlice({
    name: 'accounts',
    initialState: initialState,
    reducers: {
        setAccountList: ( state: AccountsState, action: PayloadAction<Account[]> ) => {
            state.accounts = action.payload;

            return state;
        },
        setError: ( state: AccountsState, action: PayloadAction<string> ) => {
            state.error = action.payload;

            return state;
        },
        setLoaded: ( state: AccountsState, action: PayloadAction<boolean> ) => {
            state.loaded = action.payload;

            return state;
        },
        setSelectedAccount: ( state: AccountsState, action: PayloadAction<number> ) => {
            const account = state.accounts.filter( account => account.id === action.payload );

            if ( !account ) {
                return;
            }

            state.selectedAccount = action.payload;

            return state;
        }
    }
})

export const accountsReducer = accountsSlice.reducer;
export const { setAccountList, setError, setLoaded, setSelectedAccount } = accountsSlice.actions;
